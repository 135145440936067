<app-status-bar *ngIf="environment === 'stage'" class="absolute top-0 w-full left-0"></app-status-bar>
<div class="card card-outline card-primary dark-mode">
  <div class="card-header text-center">
    <h1 *ngIf="environment === 'prod'" class="m-0">Progetto<b>Crespi</b></h1>
    <h1 *ngIf="environment === 'stage'" class="m-0">Progetto<b>Crespi</b> Stage</h1>
    <h1 *ngIf="environment === 'local'" class="m-0">Progetto<b>Crespi</b> Local</h1>
    <h2 *ngIf="version" class="text-sm">{{ version }}</h2>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Accedi al nuovo applicativo!</p>
    <form (ngSubmit)="loginByAuth()" [formGroup]="loginForm">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="username"
          placeholder="Username"
          type="text"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          [type]="showPassword ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
          placeholder="Password"
          type="password"
        />
        <div (click)="showPassword = !showPassword" *ngIf="!showPassword"
             class="input-group-text cursor-pointer show-hide-password"
        >
          <span class="fas fa-eye"></span>
        </div>
        <div (click)="showPassword = !showPassword" *ngIf="showPassword"
             class="input-group-text cursor-pointer show-hide-password"
        >
          <span class="fas fa-eye-slash"></span>
        </div>
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-7">
          <div class="icheck-primary">
            <input id="remember" type="checkbox"/>
            <label for="remember">Ricorda l'accesso</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="w-full">
          <app-button
            [additionalClass]="'btn-block'"
            [content]="'Login'"
            [disabled]="isFacebookLoading || isGoogleLoading"
            [loading]="isAuthLoading"
            [type]="'submit'"
          >
          </app-button>
        </div>
      </div>
    </form>
  </div>
</div>
