<app-status-bar *ngIf="environment === 'stage'" class="absolute top-0 w-full left-0"></app-status-bar>
<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <h1 class="m-0">Progetto<b>Crespi</b> {{ environment }}</h1>
  </div>
  <div class="card-body">
    <p class="login-box-msg">
      You forgot your password? Here you can easily retrieve a new
      password.
    </p>
    <form (ngSubmit)="forgotPassword()" [formGroup]="forgotPasswordForm">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="email"
          placeholder="Email"
          type="email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-button [type]="'submit'">
            Request new password
          </app-button>
        </div>
      </div>
    </form>
    <p class="mt-3 mb-1">
      <a [routerLink]="['/login']">Login</a>
    </p>
  </div>
</div>
