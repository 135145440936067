import {Component, inject, OnInit} from '@angular/core';
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {ActivatedRoute, Params} from "@angular/router";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {RentalInsuranceTableSettings} from "@/table-settings/rentals/rental-insurance-table-settings";
import {Registry} from "@/registry.service";
import {
  DialogFormRentalInsuranceComponent
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/dialog-form-rental-insurance/dialog-form-rental-insurance.component";

@Component({
  selector: 'app-rental-insurance-list',
  templateUrl: './rental-insurance-list.component.html',
})
export class RentalInsuranceListComponent implements OnInit {
  displayedColumns = RentalInsuranceTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.rentalInsuranceService);
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  reloadTrigger$ = new Subject<any>();
  id: any;

  constructor(private rentalInsuranceService: RentalInsuranceService, private route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
  }

  btnDetailsClickFunction(obj: any) {
    let dialogRef = this.dialog.open(DialogFormRentalInsuranceComponent, {
      data: obj,
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    Registry.getInstance().set('DialogFormRentalInsuranceComponent', dialogRef);
  }

  ngOnInit(): void {

  }
}
