import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {faDownload} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-table-group-rows',
  templateUrl: './table-group-rows.component.html',
  styleUrls: ['./table-group-rows.component.scss']
})
export class TableGroupRowsComponent implements OnChanges {
  @Input() columns: {
    type: string;
    field: string;
    header: string
  }[] = [];
  @Input() data: any[] = [];
  @Input() subColumns: {
    type: string;
    field: string;
    header: string
  }[] = [];
  @Input() subRowName: string = null
  @Output() buttonClick = new EventEmitter<unknown>();
  @Input() showButtonRow: boolean = false;
  @Input() icon: any = faDownload;
  @Input() showSumCounter: boolean = false;
  expandedRow: any = null;
  columnSums: { [key: string]: number } = {};


  onRowClick(row: any): void {
    // Toggle expansion for the clicked row
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data.length !== 0 && changes.data) {
      this.calculateSums();
    }
  }

  buttonClickRow(row: any) {
    this.buttonClick.emit(row);
  }

  protected formatValue(value: any, formatType: string): any {
    if (formatType === 'date' && value !== null) {
      return new Date(value).toLocaleDateString(); // Assuming value is a date string or timestamp
    } else if (formatType === 'currency') {
      return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(value);
    }
    return value;
  }

  private calculateSums(): void {
    this.columnSums = {};
    this.columns.forEach(column => {
      this.columnSums[column.field] = this.data.reduce((sum, row) => {
        let value = row[column.field];

        if (typeof value === 'string' && value.includes('€')) {
          value = parseInt(value.replace(/[^0-9.-]+/g, ''));
        }

        if (typeof value === 'number') {
          return sum + value;
        }

        return null;
      }, 0);
    });
  }
}
