import {TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";

export class RentalMovementTableSettings {

  public static getColumnsSetting(): TableColumnsSetting<keyof RentalMovementModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID',
        filter: {
          type: "input"
        }
      },
      {
        name: "stato",
        text: 'STATO',
        filter: {
          type: "input"
        }
      },
      {
        name: "tipoNoleggio",
        text: 'Tipo Noleggio',
        filter: {
          type: "input"
        }
      },
      {
        name: "categoriaNoleggio",
        text: 'Categoria Noleggio',
        filter: {
          type: "input"
        }
      },
      {
        name: "prezzo",
        text: 'Prezzo',
        filter: {
          type: "input"
        }
      },
      {
        name: "carburanteConsegna",
        text: 'Carburante Consegna',
        filter: {
          type: "input"
        }
      },
      {
        name: "carburanteRientro",
        text: 'Carburante Rientro',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataConsegna",
        text: 'Data Consegna',
        filter: {
          type: 'date',
        }
      },
      {
        name: "dataRientro",
        text: 'Data Rientro',
        filter: {
          type: 'date',
        }
      },
      {
        name: "kmConsegna",
        text: 'Km Consegna',
        filter: {
          type: "input"
        }
      },
      {
        name: "kmRientro",
        text: 'Km Rientro',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataUltimaRilevazioneKm",
        text: 'Data Ultima Rilevazione Km',
        filter: {
          type: "input"
        }
      },
      {
        name: "kmRilevati",
        text: 'Km Rilevati',
        filter: {
          type: "input"
        }
      },
      {
        name: "nome",
        text: 'Nome',
        filter: {
          type: "input"
        }
      },
      {
        name: "cognome",
        text: 'Cognome',
        filter: {
          type: "input"
        }
      },
      {
        name: "patente",
        text: 'Patente',
        filter: {
          type: "input"
        }
      },
      {
        name: "patenteRilasciataDa",
        text: 'Patente Rilasciata Da',
        filter: {
          type: "input"
        }
      },
      {
        name: "via",
        text: 'Via',
        filter: {
          type: "input"
        }
      },
      {
        name: "citta",
        text: 'Città',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataContratto",
        text: 'Data Contratto',
        filter: {
          type: "input"
        }
      },
      {
        name: "luogoContratto",
        text: 'Luogo Contratto',
        filter: {
          type: "input"
        }
      }
    ];
  }
}
