import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ContactSourceModel} from "@/models/tables/contact-source.model";
import {ContactSourceService} from "@services/data-source-tables/contact-source.service";
import {
  DialogContactSourceComponent
} from "@pages/tables/contact-source/dialog-contact-source/dialog-contact-source.component";

@Component({
  selector: 'app-button-detail-contact-source',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailContactSourceComponent extends ButtonComponent implements OnInit {
  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private contactSourceModel: ContactSourceModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, protected contactSourceService: ContactSourceService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Origine contatto'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.contactSourceModel = <ContactSourceModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogContactSourceComponent, {
      data: this.contactSourceModel,
    });

    Registry.getInstance().set('DialogContactSourceComponentModify', this.dialogOpened)
  }

}
