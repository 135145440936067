import {ApiService} from "@services/api.service";
import {TableDataApiParams} from "@services/practices.service";
import {Observable, throwError} from "rxjs";
import {TablePaginationRespModel} from "@/models/table-pagination-resp.model";
import {catchError, map, tap} from "rxjs/operators";
import {LoggerService} from "@services/logger.service";
import {Injectable} from "@angular/core";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";

@Injectable({
    providedIn: 'root'
})
export class RentalInsuranceService extends ApiService {
    getDataForTable(params?: TableDataApiParams): Observable<TablePaginationRespModel<RentalInsuranceModel>> {
        const id = params.id;
        delete params.id;

        return new Observable<TablePaginationRespModel<RentalInsuranceModel>>(observer => {
            this.http.get<[any]>(
                this.baseUrl + `/rental-practices/${id}/insurances`)
                .pipe(
                    tap(response => {
                        const newResponse = {} as TablePaginationRespModel<RentalInsuranceModel>;
                        newResponse.content = response;
                        newResponse.totalElements = response.length;

                        observer.next(newResponse);
                        observer.complete();
                    }),
                    catchError(error => {
                        observer.error(error);

                        return throwError(error);
                    })
                ).subscribe();
        });
    }

    deleteRentalInsurance(id: number) {
        return this.http.delete(this.baseUrl + `/rental-practices/${id}`)
            .pipe(map((response: any) =>
                    tap(() => {
                        LoggerService.log(response, 'deletePractice')

                        this.triggerUpdate();
                        this.toastr.success('Rental practice deleted successfully');
                    }),
                catchError(error => {
                    this.toastr.error('Failed to delete rental practice');
                    return throwError(error);
                })
            ));
    }

    async getRentalInsurance(id: number) {
        return this.http.get<RentalInsuranceModel>(this.baseUrl + `/rental-practices/${id}/detail`).toPromise();
    }

    updateRentalInsurance(rentPractice: RentalInsuranceModel) {
        return this.http.post<RentalInsuranceModel>(this.baseUrl + '/rental-practices/save/', rentPractice).pipe(map((response: any) => {
            LoggerService.log(response, 'updateRentalPractice')

            return response;
        }));
    }
}
