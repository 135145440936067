import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {WarrantyService} from "@services/data-source-tables/warranty.service";
import {WarrantyModel} from "@/models/tables/warranty.model";
import {WarrantyFormSetting} from "@/form-settings/warranty-form-setting";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";

@Component({
  selector: 'app-warranty-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class WarrantyFormComponent extends DynamicFormComponent implements OnInit, DynamicFormPopUpInterface {
  constructor(protected router: Router, protected userService: UserService, protected warrantyService: WarrantyService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let warranty = <WarrantyModel>this.form.getRawValue()

    this.warrantyService.saveWarranty(warranty).subscribe({
      next: () => {
        this.toastr.success('Garanzia salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveWarranty failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della garanzia.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  bottomButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogWarrantyComponentModify').close()
    } else {
      Registry.getInstance().get('DialogWarrantyComponentNew').close()
    }
  }

  setupMeta(): void {
    this.meta = WarrantyFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogWarrantyComponentModify').close()
    } else {
      Registry.getInstance().get('DialogWarrantyComponentNew').close()
    }

    Registry.getInstance().get('WarrantyComponent').loadWarranties()
  }
}
