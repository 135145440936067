export class RentalMovementModel {
  public id: number;
  public stato: string;
  public tipoNoleggio: string;
  public categoriaNoleggio: string;
  public prezzo: number;
  public carburanteConsegna: string;
  public carburanteRientro: string;
  public dataConsegna: Date;
  public dataRientro: Date;
  public kmConsegna: number;
  public kmRientro: number;
  public dataUltimaRilevazioneKm: Date;
  public kmRilevati: number;
  public nome: string;
  public cognome: string;
  public patente: string;
  public patenteRilasciataDa: string;
  public via: string;
  public citta: string;
  public dataContratto: Date;
  public luogoContratto: string;
}
