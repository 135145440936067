<div class="container-box">
  <app-table-header (btnNewClick)="newButtonTrigger$.next()" (btnUpdateClick)="reloadTrigger$.next()"
                    [showNewButton]="true"
                    tableTitle="Gestione Usato">
  </app-table-header>
  <div class="table-container">
    <app-new-table
      (btnCustomClickEvent)="downloadModelWithdrawalSheetUsedVehicle($event)"
      (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
      [btnCustomFaIcon]="faDownload"
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [initialSort]="{
          active: 'created',
          direction: 'desc'
        }"
      [reloadTrigger$]="reloadTrigger$"
      btnCustomToolTip="Scarica MODELLO PRESA IN CARICO FOGLIO DI RITIRO"
    ></app-new-table>
  </div>
</div>
