import {ControlBase} from "@components/dynamic-form/control-base";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {Component, Input, OnInit} from "@angular/core";


@Component({
  template: ''
})
export abstract class InputFieldComponent implements OnInit {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup
  @Input() indexRow: number = null
  @Input() keyformArrayName: string = ''

  protected class: string;
  protected submitted: boolean = false;
  protected control: AbstractControl<any>;

  public constructor() {
    Registry.getInstance().get('formService').onUpdate().subscribe(() => {
      this.submitted = true
    });

    this.control = new FormControl('')
  }

  get controlArray() {
    return this.form.controls[this.keyformArrayName] as FormArray;
  }

  ngOnInit(): void {
    if (this.indexRow !== null) {
      let controls = this.controlArray

      this.control = controls.at(this.indexRow).get(this.meta.key) ?? new FormControl('')
    } else {
      this.control = this.form.controls[this.meta.key]
    }

  }

  is_disabled() {
    const control = this.form.controls[this.meta.key] ?? null

    return control?.disabled ?? !!this.meta.disabled;
  }

  protected setControl() {
    if (this.indexRow !== null) {
      let controls = this.controlArray

      this.control = controls.at(this.indexRow).get(this.meta.key)
    } else {
      this.control = this.form.controls[this.meta.key]
    }
  }

  protected customActionInput(event, indexRow = null, inputElement?: HTMLInputElement) {
    LoggerService.log('Input Changed!')

    if (this.meta.classToUse && this.meta.customAction && Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction]) {
      Registry.getInstance().get(this.meta.classToUse)[this.meta.customAction](event.target.value, indexRow)
    } else {
      LoggerService.log('Any function triggered')
    }
  }
}
