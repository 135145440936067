import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {LoggerService} from "@services/logger.service";
import {UserService} from "@services/admin/user.service";

@Component({
  selector: 'app-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  error: string;
  method: string;
  submitted = false;
  meta!: Array<any>
  data: any = {}
  username: string

  constructor(private route: ActivatedRoute, private userService: UserService) {
    this.route.params.subscribe((params: Params) => {
      this.username = params['username'];
    });
  }

  async ngOnInit() {
    this.userService.getByUsername(this.username).subscribe({
      next: (user) => {
        this.data = user;
      },
      error: (error) => {
        LoggerService.error('getProfile failed!');
        LoggerService.error(error);
      },
      complete: () => LoggerService.log('Request done'),
    });
  }
}
