import {Component, OnChanges} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {AccountDataPracticeFormSettings} from "@/form-settings/practice/account-data-practice-form-settings";
import {ToastrService} from "ngx-toastr";
import {UserService} from "@services/admin/user.service";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-accounting-practice-form',
  templateUrl: '../../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class AccountingPracticeFormComponent extends DynamicFormComponent implements OnChanges {

  constructor(protected toastr: ToastrService, protected userService: UserService) {
    super(toastr, userService)

    this.submitTopButton = false
    this.submitBottomButton = false
    this.setupMeta()

    Registry.getInstance().set('AccountingDataPracticeFormComponent', this)
  }

  public ngOnChanges() {
    this.refreshForm()

    if (Object.values(this.data).length !== 0) {
      this.setupMeta()
    }
  }

  setupMeta(): void {
    this.meta = AccountDataPracticeFormSettings.getMeta()
  }
}
