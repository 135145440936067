import {Component} from "@angular/core";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UserService} from "@services/admin/user.service";
import {Registry} from "@/registry.service";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";
import {ToastrService} from "ngx-toastr";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {LoggerService} from "@services/logger.service";
import {
  RentalPracticeFormMeta
} from "@pages/rental-management/rent-practice-detail/rent-practice-detail-form/rental-practice-form-meta";
import {Router} from "@angular/router";
import {
  DialogRentablePracticeNewComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-rentable-practice-new/dialog-rentable-practice-new.component";

@Component({
  selector: 'app-rentable-practice-form',
  templateUrl: '../../../../../../../components/dynamic-form/dynamic-form.component.html',
})

export class FormCreateRentablePracticeComponent extends DynamicFormComponent {

  constructor(protected toastr: ToastrService, protected userService: UserService, protected router: Router, protected rentalPracticeService: RentalPracticeService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    Registry.getInstance().set('AccessoryPracticeFormComponent', this)
  }

  public onSubmit() {
    let rentalPracticeModel = <RentalPracticeModel>this.form.getRawValue()
    rentalPracticeModel.marca = this.data.marca;

    this.rentalPracticeService.updateRentalPractice(rentalPracticeModel).subscribe({
      next: (response: RentalPracticeModel) => {
        let practiceId = response.id

        this.toastr.success('Pratica aggiornata correttamente!');

        this.router.navigate([`rental-practices/detail-practice/${practiceId}`])
      },
      error: (error) => {
        LoggerService.error('savePractice failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della pratica.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')
        Registry.getInstance().get('DialogRentablePracticeNewComponent').close()

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = RentalPracticeFormMeta.getMeta()
  }
}
