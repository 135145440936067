import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {
  DialogMenuBiConfigComponent
} from "@pages/tables/menu-bi-config/dialog-menu-biconfig/dialog-menu-bi-config.component";
import {MenuBiConfigModel} from "@/models/tables/menu-bi-config.model";

@Component({
  selector: 'app-button-detail-menu-bi',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailMenuBIComponent extends ButtonComponent implements OnInit {

  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private menuBI: MenuBiConfigModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Menu BI'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.menuBI = <MenuBiConfigModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogMenuBiConfigComponent, {
      data: this.menuBI,
    });

    Registry.getInstance().set('DialogMenuBIConfigComponentModify', this.dialogOpened)
  }

}
