import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {DuplicatePracticeFormSetting} from "@/form-settings/duplicate-practice-form-setting";

@Component({
  selector: 'app-duplicate-practice-form',
  templateUrl: '../../../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class DuplicatePracticeFormComponent extends DynamicFormComponent implements OnInit {

  protected practicesVehicles: any[] = [];

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Duplica'
    this.secondaryBottomButton = 'Chiudi'

    Registry.getInstance().set('DuplicatePracticeFormComponent', this)
  }

  ngOnInit(): void {
    LoggerService.log(this.data)

    this.form.controls['praticaId'].setValue(this.data.id)
  }

  onSubmit() {
    let practiceToDuplicate = this.form.getRawValue()
    const duplication = {
      'praticaId': practiceToDuplicate.praticaId,
      'numeriPratiche': []
    }

    LoggerService.log(practiceToDuplicate.numeriPratiche)

    for (let numeroPratica of practiceToDuplicate.numeriPratiche) {
      duplication.numeriPratiche.push(numeroPratica.codicePratica)
    }

    LoggerService.log(duplication)

    this.practiceService.clonePractice(duplication).subscribe({
      next: () => {
        this.toastr.success('Pratica duplicata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('clonePractice failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante la duplicazione.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = DuplicatePracticeFormSetting
      .setClassToCall('DuplicatePracticeFormComponent')
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    Registry.getInstance().get('DialogDuplicatePracticeComponent').close()
  }

  bottomButtonAction() {
    this.closeModalAndRefresh()
  }
}
