import {Component, Input, OnChanges} from '@angular/core';
import {PracticeModel} from "@/models/practice.model";
import {TableColumnsSetting} from "@/interfaces/table-setting";
import {SellerPracticeInvoiceDataSource} from "@components/new-table/data-sources/seller-practice-invoice.dataSource";
import {BuyerPracticeInvoiceDataSource} from "@components/new-table/data-sources/buyer-practice-invoice.dataSource";
import {BuyerPracticeInvoiceSettingsTable} from "@/table-settings/practices/buyer-practice-invoice-settings-table";
import {SellerPracticeInvoiceSettingsTable} from "@/table-settings/practices/seller-practice-invoice-settings-table";
import {Subject} from "rxjs";

@Component({
  selector: 'app-accounting-tab-component',
  templateUrl: './accounting-tab-component.component.html',
  styleUrls: ['./accounting-tab-component.component.scss']
})
export class AccountingTabComponentComponent implements OnChanges {
  @Input() formData!: PracticeModel;
  panelOpenState: boolean;
  displayedColumnsSellerInvoice: TableColumnsSetting<any>;
  dataSourceSellerInvoice = null;
  dataSourceBuyerInvoice = null;
  displayedColumnsBuyerInvoice: TableColumnsSetting<any>;
  reloadTrigger$ = new Subject<any>();
  columnsQuadraturaPractice = [
    {field: 'documentNo', header: 'Document No.', type: 'string'},
    {field: 'numeroPraticaRef', header: 'Nr. Pratica', type: 'string'},
    {field: 'numeroRegistrazione', header: 'Numero Registrazione', type: 'string'},
    {field: 'dataScritturaContabilita', header: 'Data Scrittura', type: 'date'},
    {field: 'reasonCode', header: 'Reason Code', type: 'string'},
    {field: 'sourceCode', header: 'Source Code', type: 'string'},
    {field: 'descrizione', header: 'Descrizione', type: 'string'},
    {field: 'saldo', header: 'Saldo', type: 'currency'}
  ];
  columnsMovimentiCassa = [
    {field: 'reparto', header: 'Reparto', type: 'string'},
    {field: 'dataMovimento', header: 'Data movimento', type: 'date'},
    {field: 'descrizioneMovimento', header: 'Descrizione', type: 'string'},
    {field: 'importo', header: 'Importo', type: 'currency'},
    {field: 'tipoPagamento', header: 'Tipo pagamento', type: 'string'},
    {field: 'note', header: 'Note', type: 'string'},
    {field: 'creator', header: 'Creato da', type: 'string'}
  ];
  dataTableQuadraturaPractice: any[] = [];
  dataTableQuadraturaPractice2: any[] = [];
  dataTableMovimentiCassa: any[] = [];

  constructor() {
    this.displayedColumnsBuyerInvoice = BuyerPracticeInvoiceSettingsTable.getColumnsSetting();
    this.displayedColumnsSellerInvoice = SellerPracticeInvoiceSettingsTable.getColumnsSetting();
  }

  public ngOnChanges() {
    if (Object.values(this.formData).length !== 0) {
      this.dataSourceSellerInvoice = new SellerPracticeInvoiceDataSource();
      this.dataSourceSellerInvoice.content = this.formData.lineaFatturaVenditaList;
      this.dataSourceSellerInvoice.totalElements = 1;

      this.dataSourceBuyerInvoice = new BuyerPracticeInvoiceDataSource();
      this.dataSourceBuyerInvoice.content = this.formData.lineaFatturaAcquistoList;
      this.dataSourceBuyerInvoice.totalElements = 1;

      this.dataTableMovimentiCassa = this.formData.movimentiPraticaList;
      this.dataTableQuadraturaPractice = this.formData.quadraturaContabilitaPraticaList;
      this.dataTableQuadraturaPractice2 = this.formData.quadraturaContabilitaPratica2List;
    }
  }
}
