import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PracticeAccessoryModel} from "@/models/practice-accessory-model";
import {
  DialogDetailAccessoryPracticeComponent
} from "@pages/practices-management/practices/practice-details/dialogs/dialog-detail-accessory/dialog-detail-accessory-practice.component";

@Component({
  selector: 'app-button-practice-accessory-detail',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDetailPracticeAccessoryComponent extends ButtonComponent implements OnInit {

  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private accessoryModel: PracticeAccessoryModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.accessoryModel = <PracticeAccessoryModel>this.rowData;
  }

  /**
   * Open practice detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogDetailAccessoryPracticeComponent, {
      data: this.accessoryModel,
    });

    Registry.getInstance().set('DialogDetailAccessoryPracticeComponent', this.dialogOpened)
  }
}
