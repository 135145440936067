import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {Registry} from "@/registry.service";
import {PracticesService} from "@services/practices.service";
import {LoggerService} from "@services/logger.service";
import {PracticeDefaultValues, PracticeModel} from "@/models/practice.model";
import {FormArray, Validators} from "@angular/forms";
import {OptionSelect} from "@/models/option-select.model";
import {ToastrService} from "ngx-toastr";
import {PracticeForm} from "@/form-settings/practice-form";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {CurrencyInputDirective} from "@/directives/currency-input.directive";
import {DialogLoadingComponent} from "@components/dialog-loading/dialog-loading.component";
import {concatMap, tap} from "rxjs/operators";
import {BrandModel} from "@/models/tables/brand.model";
import {SellerModel} from "@/models/tables/seller.model";
import {Role} from "@/models/role";

@Component({
  selector: 'app-practice-form-new',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class NewPracticeFormComponent extends DynamicFormComponent implements OnInit {

  protected sellerCodes: any[] = []
  protected brands: OptionSelect[] = [];
  protected sellerCodesSelect: OptionSelect[] = [];
  protected clientType: OptionSelect[] = [];
  protected paymentsTypes: any[] = [];
  protected rentalCodes: OptionSelect[] = [];
  protected practiceTypes: OptionSelect[] = [];
  protected actionCodes: any[] = [];
  protected loyaltyServices: any[] = [];
  protected financialServices: any[] = [];
  protected models: any[] = [];
  protected practiceTypeSelected: string = '';
  protected signalman: boolean = false;
  protected agenziaSubagente: SellerModel['agenziaSubagente'];
  protected sellerSelected: boolean = false;
  protected bigBuyers: OptionSelect[] = [];
  protected practiceTypeDisabled: boolean;
  protected subAgentUsers: OptionSelect[] = [];
  protected contactSource: any[] = [];
  protected warranties: any[] = [];
  protected fieldArriveFromCross: boolean = false;
  private rentalAgencySelect: OptionSelect[] = [];
  private firstSetupPracticeType: boolean = true;
  private dialogLoading: MatDialogRef<DialogLoadingComponent, any>;
  private hideTypeBuyer: boolean = true;
  private showCommissionFields: boolean;
  private commercialVehicleTypes: OptionSelect[] = [];
  private commercialVehicleTypeActive: any;
  private percentagesCommissionSeller: OptionSelect[] = [];

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService, public dialog: MatDialog) {
    super(toastr, userService)
    const rolesUser = this.userService.getUserRoles();
    this.hideTypeBuyer = !(rolesUser.includes(Role.Admin) || rolesUser.includes(Role.ResponsibleSellers) || rolesUser.includes(Role.Accounting))
    this.setupMeta()

    if (
      rolesUser.includes('ROLE_ADMINISTRATOR') ||
      rolesUser.includes('ROLE_VENDITORE') ||
      rolesUser.includes('ROLE_RESPONSABILE_VENDITORE') ||
      rolesUser.includes('ROLE_CONTABILITA')
    ) {
      this.showCommissionFields = true;
    }

    this.primaryButton = 'Salva'

    Registry.getInstance().set('NewPracticeFormComponent', this)
  }

  ngOnInit(): void {
    this.practiceTypeDisabled = false

    this.practiceService.getSellers().subscribe((sellers) => {
      this.sellerCodes = sellers

      for (let sellerCode of sellers) {
        this.sellerCodesSelect.push({
          'key': sellerCode,
          'value': sellerCode.codiceVenditore + ' - ' + sellerCode.descrizione
        })
      }

      this.setupMeta()
    });
    this.practiceService.getRentalAgency().subscribe((rentalAgency) => {
      for (let agency of rentalAgency) {
        this.rentalAgencySelect.push({
          'key': agency,
          'value': agency.codiceAgenzia + ' - Agenzia Noleggio ' + agency.codiceAgenzia
        })
      }

      this.setupMeta()
    });
    this.practiceService.getRentalCodes().subscribe((rentalCodes) => {
      for (let rentalCode of rentalCodes) {
        this.rentalCodes.push({
          'key': rentalCode,
          'value': rentalCode.descrizione
        })
      }

      this.setupMeta()
    });
    this.practiceService.getPracticeTypes().subscribe((practiceTypes) => {
      for (let practiceType of practiceTypes) {
        this.practiceTypes.push({
          'key': practiceType,
          'value': practiceType
        })
      }

      this.setupMeta()
    });
    this.practiceService.getTypesCommercialVehicle().subscribe((commercialVehicleTypes) => {
      for (const type of commercialVehicleTypes) {
        this.commercialVehicleTypes.push({
          'key': type,
          'value': type.descrizione
        })
      }

      this.setupMeta()
    });

    if (Object.values(this.data).length !== 0 && this.data.venditore) {
      this.sellerChanges(this.data.venditore)
    }

    const vehicleType = this.form.controls['tipoVeicoloCommerciale'].value ?? false;
    this.commercialVehicleTypeActive = vehicleType !== false;
  }

  public onSubmit() {
    let practice = <PracticeModel>this.form.getRawValue()
    practice = this.setDefaultValues(practice);

    this.practiceService.savePractice(practice).subscribe({
      next: (response) => {
        let practiceId = response.id

        this.toastr.success('Pratica creata correttamente!');

        this.router.navigate([`practices-management/practices/details/${practiceId}`])
      },
      error: (error) => {
        LoggerService.error('savePractice failed!');
        LoggerService.error(error);

        this.manageErrorMessages(error)

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  public openDialog() {
    this.dialogLoading = this.dialog.open(DialogLoadingComponent, {disableClose: true});
  }

  setupMeta(): void {
    LoggerService.log('setup meta')

    this.meta = PracticeForm
      .setFieldArriveFromCross(this.fieldArriveFromCross)
      .setHideTypeBuyer(this.hideTypeBuyer)
      .setTypePracticeDisabled(this.practiceTypeDisabled)
      .setClassToCall('NewPracticeFormComponent')
      .setTypePractice(this.practiceTypeSelected)
      .setTypePayments(this.paymentsTypes)
      .setPracticeTypes(this.practiceTypes)
      .setSubAgentUsers(this.subAgentUsers)
      .setSellers(this.sellerCodesSelect)
      .setRentalCodes(this.rentalCodes)
      .setClientType(this.clientType)
      .setActionCodes(this.actionCodes)
      .setBrands(this.brands)
      .setModels(this.models)
      .setRentalAgency(this.rentalAgencySelect)
      .setBigBuyers(this.bigBuyers)
      .setLoyaltyServices(this.loyaltyServices)
      .setFinancialServices(this.financialServices)
      .setSellerSelected(this.sellerSelected)
      .setSignalman(this.signalman)
      .setContactSource(this.contactSource)
      .setWarranties(this.warranties)
      .setShowCommissionFields(this.showCommissionFields)
      .setTypeCommercialVehicleList(this.commercialVehicleTypes)
      .setTypeCommercialVehicleActive(this.commercialVehicleTypeActive)
      .setPercentagesCommissionSeller(this.percentagesCommissionSeller)
      .getForm()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected practiceTypeChanged(practiceType, indexRow = null) {
    this.openDialog();

    const practiceTypeOld = this.data.tipoPratica ?? ''
    this.practiceTypeSelected = practiceType

    if (this.firstSetupPracticeType) {
      this.refreshDataPracticeType(practiceType)
      this.form.controls['tipoPratica'].setValue(practiceType)
      this.firstSetupPracticeType = false

      return
    }

    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupChangePracticeTypePopup(dialogRef)

    dialogRef.afterClosed().subscribe(changePracticeType => {
      if (changePracticeType) {
        this.data.tipoPratica = practiceType

        this.setupMeta()

        this.refreshDataPracticeType(practiceType)
      } else {
        this.form.controls['tipoPratica'].setValue(practiceTypeOld)
        this.practiceTypeSelected = practiceTypeOld

        this.setupMeta()
      }
    })
  }

  protected refreshDataPracticeType(practiceType: string) {
    this.practiceTypeSelected = practiceType

    this.practiceService.getBrandsBypracticeType(practiceType).pipe(
      tap((brands) => {
        this.brands = []

        for (let brand of brands) {
          this.brands.push({
            'key': brand,
            'value': brand.codiceMarca + ' - ' + brand.descrizioneMarca
          })
        }

        this.setupMeta()
      }),
      concatMap(() => this.practiceService.getAnagraficaServices(this.practiceTypeSelected, 'FINANZIARI')),
      tap((financialServices) => {
        for (let financialService of financialServices) {
          financialService.textOption = financialService.descrizione
        }

        this.financialServices = financialServices
        this.setupMeta()
      }),
      //TODO FARLO CONDIZIONATO AL TIPO PRATICA NUOVO
      concatMap(() => this.practiceService.getAnagraficaServices(this.practiceTypeSelected, 'LOYALTY')),
      tap((loyaltyServices) => {
        for (let loyaltyService of loyaltyServices) {
          loyaltyService.textOption = loyaltyService.descrizione
        }

        this.loyaltyServices = loyaltyServices
        this.setupMeta()
      }),
      concatMap(() => this.practiceService.getPaymentTypesByPracticeType(this.practiceTypeSelected)),
      tap((paymentTypes) => {
        this.paymentsTypes = paymentTypes
        this.setupMeta()
      }),
      //TODO FARLO CONDIZIONATO AL TIPO PRATICA USATO
      concatMap(() => this.practiceService.getContactSource()),
      tap((contactSources) => {
        this.contactSource = contactSources
        this.setupMeta()
      }),
      //TODO FARLO CONDIZIONATO AL TIPO PRATICA USATO
      concatMap(() => this.practiceService.getWarranties()),
      tap((warranties) => {
        this.warranties = warranties
        this.setupMeta()
      }),
      concatMap(() => this.practiceService.getCustomerTypesByPracticeType(this.practiceTypeSelected)),
      tap((customerTypes) => {
        this.clientType = []
        for (let customerType of customerTypes) {
          this.clientType.push({
            'key': customerType,
            'value': customerType
          })
        }
        this.setupMeta()
      })
    ).subscribe(ret => {
      if (this.dialogLoading) {
        this.dialogLoading.close()
      }
    })

    this.refreshForm()
  }

  protected sellerChanges(seller: SellerModel, indexRow = null) {
    this.signalman = seller.segnalatore ?? false;

    this.form.controls['segnalatore'].setValue(seller.segnalatore);

    if (seller.segnalatore) {
      this.form.controls['noteSegnalatore'].setValue(seller.descrizione);
      this.form.controls['noteSegnalatore'].enable();
    } else {
      this.form.controls['noteSegnalatore'].setValue(null);
      this.form.controls['noteSegnalatore'].disable();
    }

    if (seller.agenziaSubagente != null) {
      this.agenziaSubagente = seller.agenziaSubagente;
    }

    if (seller.agenziaSubagente != null) {
      this.subAgentUsers = []

      for (const user of seller.users) {
        this.subAgentUsers.push({
          key: user.username,
          value: user.username,
        });
      }

      this.agenziaSubagente = seller.agenziaSubagente;
      this.form.controls['utenteSubagente'].enable();

      const username = this.userService.getUserName()
      let usernameIsPresent = this.subAgentUsers.filter(v => v.key === username)

      if (usernameIsPresent) {
        this.form.controls['utenteSubagente'].setValue(username)
      }
    } else {
      this.form.controls['utenteSubagente'].disable();
      this.form.controls['utenteSubagente'].setValue(null)
    }

    this.percProvvigioneUpdateByClient(seller)
    this.setupMeta()
  }

  protected percProvvigioneUpdateByClient(seller: SellerModel) {
    const sellerType = seller.tipoVenditore;

    this.practiceService.getCommissionPercentageBySellerType(sellerType).subscribe({
      next: (response) => {
        this.percentagesCommissionSeller = []

        for (let percentage of response) {
          this.percentagesCommissionSeller.push({
            'key': percentage.percentualeProvvigione,
            'value': percentage.percentualeProvvigione.toString()
          })
        }

        if (response.length > 0) {
          this.percProvvigioneChange()
          this.form.controls['percProvvigione'].enable()
          this.setupMeta()
        } else {
          this.form.controls['percProvvigione'].disable()
          this.form.controls['percProvvigione'].setValue(null)
        }
      },
      error: (error) => {
        LoggerService.error('getCommissionPercentageBySellerType failed!');
        LoggerService.error(error);
      }
    });
  }

  protected brandsChanges(brandId: BrandModel['id'], indexRow = null) {
    LoggerService.log('brandsChanges')

    brandId = typeof brandId === 'object' ? (brandId as BrandModel).id : brandId;

    if (this.practiceTypeSelected === 'NUOVO') {
      this.practiceService.getActionCodes(brandId).subscribe((actionCodes) => {
        this.actionCodes = []

        for (let actionCode of actionCodes) {
          actionCode.textOption = actionCode.codiceAzione + ' - ' + actionCode.descrizione;
        }

        this.actionCodes = actionCodes;

        this.setupMeta()
      })
      this.practiceService.getModelsByBrandId(brandId).subscribe((models) => {
        this.models = models;
        this.setupMeta()
      })

      const clientType = this.form.controls['tipoCliente'].value ?? this.data.tipoCliente

      if (clientType) {
        this.practiceService.getBigBuyers(brandId, clientType).subscribe((bigBuyers) => {
          this.bigBuyers = []

          for (let bigBuyer of bigBuyers) {
            this.bigBuyers.push({
              'key': bigBuyer,
              'value': bigBuyer.codiceGA + ' - ' + bigBuyer.descrizione
            })
          }

          this.setupMeta()
        })
      }

      this.updateVehicleType(brandId)
    }

    this.updateClientType()
  }

  protected updateClientType() {
    LoggerService.log('updateClientType')

    this.form.controls['tipoCliente'].enable();
  }

  protected typeClientChanges(typeClient, indexRow = null) {
    const practice = <PracticeModel>this.form.getRawValue();

    if (practice.tipoPratica === 'NUOVO') {
      switch (typeClient) {
        case 'GRANDI_ACQUIRENTI':
          this.form.controls['grandiAcquirenti'].enable();
          this.form.controls['percMargineGA'].enable();
          break;
        case 'VETTURE_DIMOSTRATIVE':
          this.form.controls['grandiAcquirenti'].enable();
          this.form.controls['percMargineGA'].enable();
          this.form.controls['dataScadenzaAutoDemo'].enable();
          this.form.controls['assegnatarioAutoDemo'].enable();
          break;
        case 'PRIVATO':
          this.form.controls['grandiAcquirenti'].disable();
          this.form.controls['percMargineGA'].disable();
          this.form.controls['margineGA'].disable();
          break;
      }

      if (typeClient !== 'VETTURE_DIMOSTRATIVE') {
        this.form.controls['dataScadenzaAutoDemo'].reset();
        this.form.controls['dataScadenzaAutoDemo'].disable();
        this.form.controls['assegnatarioAutoDemo'].reset();
        this.form.controls['assegnatarioAutoDemo'].disable();
      }

      this.practiceService.getBigBuyers(practice.marca.id, typeClient).subscribe((bigBuyers) => {
        this.bigBuyers = []

        for (let bigBuyer of bigBuyers) {
          this.bigBuyers.push({
            'key': bigBuyer,
            'value': bigBuyer.codiceGA + ' - ' + bigBuyer.descrizione
          })
        }

        this.setupMeta()
      })
    }
  }

  protected getActionValue(actions: FormArray, indexRow: number): number {
    const actionFormValue = actions.at(indexRow).get('valore').value;

    return typeof actionFormValue === 'string' ?
      CurrencyInputDirective.getParsedValue(actionFormValue) || 0 :
      actionFormValue;
  }

  protected updateAmountsAction() {
    LoggerService.log('updateAmountsAction');

    const actions = this.form.controls['azioni'] as FormArray;

    for (let indexRow = 0; indexRow < actions.controls.length; indexRow++) {
      this.amountActionChanges(actions.controls[indexRow].value, indexRow)
    }
  }

  protected amountActionChanges(amountAction, indexRow = null) {
    const practice = <PracticeModel>this.form.getRawValue();
    const actions = this.form.controls['azioni'] as FormArray;
    const actionValue = this.getActionValue(actions, indexRow);
    const valueType = actions.at(indexRow).get('tipoValore').value;
    let amount = 0;

    if (valueType != null) {
      if (valueType == 'CON_IVA') {
        amount = actionValue / 1.22;
      } else if (valueType == 'SENZA_IVA') {
        amount = actionValue
      } else if (valueType == 'PERCENTUALE') {
        amount = practice.prezzoImponibile / 100 * actionValue
      }
    }

    let newAction = actions.at(indexRow).getRawValue()

    newAction.importo = this.reduceDecimalFloat(amount)
    actions.at(indexRow).setValue(newAction)

    this.updateTotaleAzioni();
    this.updateScontoNettoAzioni();
    this.updatePercScontoNettoAzioni();
    this.updateProvvigioneNettoAzioni();
  }

  protected removeCodiceAzione() {
    LoggerService.log('removeCodiceAzione')

    this.updateTotaleAzioni();
    this.updatePercScontoNettoAzioni();
    this.updateScontoNettoAzioni();
  }

  protected updateScontoNettoAzioni() {
    LoggerService.log('updateScontoNettoAzioni')

    const practice = <PracticeModel>this.form.getRawValue();
    const sumAction: number = practice.azioni.reduce((accumulator, object) => {
      return (object.anagAzionePratica && object.anagAzionePratica.conteggiaAzione) ? accumulator + object.importo : accumulator;
    }, 0);
    const totalDiscount: number = practice.scontoTotale;

    this.form.controls['scontoNettoAzioni'].setValue(this.reduceDecimalFloat(totalDiscount - sumAction));

    this.scontoNettoAzioniChange()
  }

  protected financialServiceChanges(financialService, indexRow = null) {
    LoggerService.log('financialServiceChanges')

    const financialServices = this.form.controls['serviziFinanziari'] as FormArray;
    let newService = {
      "punteggio": 0,
      "note": "",
      "tipoServizio": '',
      "anagServizioPratica": {},
    }

    if (financialService) {
      newService.punteggio = financialService.punteggio
      newService.note = financialService.note
      newService.tipoServizio = financialService.tipoServizio
      newService.anagServizioPratica = financialService
    }

    financialServices.at(indexRow).setValue(newService)

    if (this.practiceTypeSelected === 'NUOVO') {
      this.updateProvvigioneAccessori();
    }
  }

  protected removeFinancialService() {
    LoggerService.log('removeFinancialService');

    this.updateProvvigioneAccessori();
  }

  protected updateProvvigioneAccessori() {
    LoggerService.log('updateProvvigioneAccessori');

    const financialServices = this.form.controls['serviziFinanziari'] as FormArray;
    let totalCommissionServices = 0;
    let totCommissionsAccessory = Registry.getInstance().get('totCommissionsAccessory')

    financialServices.value.forEach((service) => {
      totalCommissionServices = totalCommissionServices + service.anagServizioPratica.provvigioneAccessorio
    })

    totCommissionsAccessory = totCommissionsAccessory + totalCommissionServices

    this.form.controls['provvigioneAccessori'].setValue(totCommissionsAccessory)

    this.provvigioneAccessoriChange()
  }

  protected securityDepositChanges(switchValue: boolean, indexRow = null) {
    if (switchValue) {
      this.form.controls['importoDepositoCauzionale'].enable();
    } else {
      this.form.controls['importoDepositoCauzionale'].disable();
    }
  }

  protected loyaltyServiceChanges(loyaltyService, indexRow = null) {
    LoggerService.log('loyaltyServiceChanges')

    const loyaltyServices = this.form.controls['serviziLoyalty'] as FormArray;
    let newService = {
      "punteggio": 0,
      "note": "",
      "tipoServizio": '',
      "anagServizioPratica": {},
    }

    if (loyaltyService) {
      newService.punteggio = loyaltyService.punteggio
      newService.note = loyaltyService.note
      newService.tipoServizio = loyaltyService.tipoServizio
      newService.anagServizioPratica = loyaltyService
    }

    loyaltyServices.at(indexRow).setValue(newService)
  }

  protected codeActionChanges(valueSelected, indexRow = null) {
    LoggerService.log('codeActionChanges')

    const actions = this.form.controls['azioni'] as FormArray;
    let actionServiceValues = actions.at(indexRow).getRawValue() ?? {}

    if (valueSelected) {
      actionServiceValues.anagAzionePratica = valueSelected
      actionServiceValues.importo = 0
      actionServiceValues.valore = 0

      if (!actionServiceValues.anagAzionePratica.conteggiaAzione) {
        const nameClass = `AutoCompleteActionCodesComponent-azioni-${indexRow}`
        const autocompleteComponent = Registry.getInstance().get(nameClass)

        autocompleteComponent.showAlert = true
      } else {
        const nameClass = `AutoCompleteActionCodesComponent-azioni-${indexRow}`
        const autocompleteComponent = Registry.getInstance().get(nameClass)

        autocompleteComponent.showAlert = false
      }
    }

    actions.at(indexRow).setValue(actionServiceValues)

    this.updateProvvigioneTotale();
  }

  protected typePaymentChanges(typePayment, indexRow = null) {
    LoggerService.log('typePaymentChanges')

    const practice = <PracticeModel>this.form.getRawValue();

    if ('NUOVO' === practice.tipoPratica) {
      switch (typePayment.id) {
        case 'NOLEGGIO':
          this.form.controls['codiceNoleggio'].enable();
          this.form.controls['agenziaNoleggio'].enable();
          this.form.controls['noteTabellaNoleggio'].enable();
          this.form.controls['codiceTabellaFin'].disable();
          this.form.controls['numPraticaFinanziamento'].enable();
          this.form.controls['importoRateFinanaziamento'].enable();
          this.form.controls['numeroRateFinanziamento'].enable();
          this.form.controls['tipoVetturaAziendale'].enable();
          break
        case 'FINANZIAMENTO':
          this.form.controls['codiceNoleggio'].disable();
          this.form.controls['agenziaNoleggio'].disable();
          this.form.controls['noteTabellaNoleggio'].disable();
          this.form.controls['codiceTabellaFin'].enable();
          this.form.controls['numPraticaFinanziamento'].enable();
          this.form.controls['importoRateFinanaziamento'].enable();
          this.form.controls['numeroRateFinanziamento'].enable();
          this.form.controls['tipoVetturaAziendale'].disable();
          break
        case 'LEASING':
          this.form.controls['codiceNoleggio'].disable();
          this.form.controls['agenziaNoleggio'].disable();
          this.form.controls['noteTabellaNoleggio'].disable();
          this.form.controls['codiceTabellaFin'].disable();
          this.form.controls['numPraticaFinanziamento'].enable();
          this.form.controls['importoRateFinanaziamento'].disable();
          this.form.controls['numeroRateFinanziamento'].disable();
          this.form.controls['tipoVetturaAziendale'].disable();
          break
        default:
          this.form.controls['codiceNoleggio'].disable();
          this.form.controls['agenziaNoleggio'].disable();
          this.form.controls['noteTabellaNoleggio'].disable();
          this.form.controls['codiceTabellaFin'].disable();
          this.form.controls['numPraticaFinanziamento'].disable();
          this.form.controls['importoRateFinanaziamento'].disable();
          this.form.controls['numeroRateFinanziamento'].disable();
          this.form.controls['tipoVetturaAziendale'].disable();
          break
      }
    } else {
      switch (typePayment.id) {
        case 'CONTANTI':
          this.form.controls['codiceTabellaFin'].disable();
          this.form.controls['numPraticaFinanziamento'].disable();
          this.form.controls['importoRateFinanaziamento'].disable();
          this.form.controls['numeroRateFinanziamento'].disable();
          break
        default:
          this.form.controls['codiceTabellaFin'].enable();
          this.form.controls['numPraticaFinanziamento'].enable();
          this.form.controls['importoRateFinanaziamento'].enable();
          this.form.controls['numeroRateFinanziamento'].enable();
          break
      }
    }
  }

  protected percProvvigioneChange() {
    LoggerService.log('percProvvigioneChange');

    this.updateProvvigioneSegnalatore()
    this.updateProvvigioneNettoAzioni()
  }

  protected percProvvigioneChangeUsed() {
    LoggerService.log('percProvvigioneChangeUsed');

    this.updateProvvigioneNettoAzioniUsed()
  }

  protected percScontoNettoAzioniChange() {
    LoggerService.log('percScontoNettoAzioniChange');
  }

  protected prezzoImponibileChange() {
    LoggerService.log('prezzoImponibileChange');

    this.updateProvvigioneNettoAzioni()
    this.updateProvvigioneSegnalatore()
    this.updatePercContributoDealerObbligatorio()
    this.updateMargineGA()
    this.updatePercScontoNettoAzioni()
    this.updateAmountsAction()
  }

  protected prezzoImponibileChangeUsed() {
    LoggerService.log('prezzoImponibileChange');

    this.updateProvvigioneNettoAzioniUsed()
  }

  protected scontoTotaleChange() {
    LoggerService.log('scontoTotaleChange');

    this.updatePercScontoNettoAzioni()
    this.updateScontoNettoAzioni()
  }

  protected provvigioneNettoAzioniChange() {
    LoggerService.log('provvigioneNettoAzioniChange');

    this.updateProvvigioneTotale()
  }

  protected scontoNettoAzioniChange() {
    LoggerService.log('scontoNettoAzioniChange');

    this.updateProvvigioneNettoAzioni()
    this.updatePercScontoNettoAzioni()
  }

  protected updateMargineGA() {
    LoggerService.log('updateMargineGA');

    const practice = <PracticeModel>this.form.getRawValue()
    const taxablePrice = practice.prezzoImponibile
    const percMarginGA = practice.percMargineGA
    const marginGACalculation = (percMarginGA / 100) * taxablePrice

    this.form.controls['margineGA'].setValue(this.reduceDecimalFloat(marginGACalculation));
  }

  protected percMargineGAChange() {
    LoggerService.log('percMargineGAChange');

    this.updateMargineGA();
  }

  protected updatePercContributoDealerObbligatorio() {
    LoggerService.log('updatePercContributoDealerObbligatorio');

    const practice = <PracticeModel>this.form.getRawValue()
    const taxablePrice = practice.prezzoImponibile;
    const dealerContributionMandatory = practice.contributoDealerObbligatorio;
    const percDealerContributionMandatory = (dealerContributionMandatory / taxablePrice) * 100

    this.form.controls['percContributoDealerObbligatorio'].setValue(this.reduceDecimalFloat(percDealerContributionMandatory));
  }

  protected percProvvigioneConcordataChange() {
    LoggerService.log('percProvvigioneConcordataChange')
  }

  protected secondButtonAction() {
    this.router.navigate(['practices-management/practices']);
  }

  protected manageErrorMessages(error: HttpErrorResponse) {
    const errorKey = error.error.key ?? ''

    switch (errorKey) {
      case "DOSSIER_CODE_ALREDY_EXISTS":
        this.toastr.error(`Numero pratica già esistente.`);
        break;
      default:
        this.toastr.error(`Errore durante il salvataggio della pratica.`);
        break;
    }
  }

  protected provvigioneAccessoriChange() {
    LoggerService.log('provvigioneAccessoriChange')

    this.updateProvvigioneTotale()
  }

  protected setDefaultValues(obj: PracticeModel): PracticeModel {
    Object.keys(PracticeDefaultValues).forEach(key => {
      if (!obj[key] || obj[key] === null || obj[key] === undefined || typeof PracticeDefaultValues[key] !== typeof obj[key] || obj[key] === 'NaN') {
        obj[key] = PracticeDefaultValues[key];
      }
    });

    return obj;
  }

  private updateVehicleType(brandId: number): void {
    if (brandId === 2) {
      this.form.controls['tipoVeicoloCommerciale'].enable();

      this.form.controls['tipoVeicoloCommerciale'].setValidators([Validators.required]);
      this.form.controls['tipoVeicoloCommerciale'].updateValueAndValidity();
    }
  }

  private updateProvvigioneNettoAzioni() {
    LoggerService.log('updateProvvigioneNettoAzioni');

    const practice = <PracticeModel>this.form.getRawValue();
    const taxablePrice = practice.prezzoImponibile;
    const discountNetShares = practice.scontoNettoAzioni;
    const percCommission = practice.percProvvigione;
    const commissionNetActions = ((taxablePrice - discountNetShares) * percCommission) / 100

    if (percCommission === 0) {
      LoggerService.error('Commissione venditore non impostata');
      return;
    }

    this.form.controls['provvigioneNettoAzioni'].setValue(this.reduceDecimalFloat(commissionNetActions));

    this.provvigioneNettoAzioniChange();
  }

  private updateProvvigioneNettoAzioniUsed() {
    LoggerService.log('updateProvvigioneNettoAzioni');

    const practice = <PracticeModel>this.form.getRawValue();
    const taxablePrice = practice.prezzoImponibile;
    const percCommission = practice.percProvvigione;
    const commissionNetActions = (taxablePrice * percCommission) / 100

    this.form.controls['provvigioneNettoAzioni'].setValue(this.reduceDecimalFloat(commissionNetActions));

    this.provvigioneNettoAzioniChange();
  }

  private updateProvvigioneSegnalatore() {
    LoggerService.log('updateProvvigioneSegnalatore');

    const practice = <PracticeModel>this.form.getRawValue();
    const taxablePrice = practice.prezzoImponibile;
    const percCommission = practice.percProvvigione;

    const commissionSignaller = (taxablePrice * percCommission) / 100

    this.form.controls['provvigioneSegnalatore'].setValue(this.reduceDecimalFloat(commissionSignaller));
  }

  private setupChangePracticeTypePopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cambiare il tipo pratica?'
    dialogRef.componentInstance.content = "Attenzione una volta cambiato il tipo pratica tutto i dati del form veranno resettati e non sarà possibile recuperarli."
    dialogRef.componentInstance.firstCTA = "Si"
    dialogRef.componentInstance.secondCTA = "Annulla"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }

  private updatePercScontoNettoAzioni() {
    LoggerService.log('updatePercScontoNettoAzioni');

    const practice = <PracticeModel>this.form.getRawValue()
    const discountNetShares = practice.scontoNettoAzioni;
    const taxablePrice = practice.prezzoImponibile;
    const percentageNetShareDiscount = (discountNetShares / taxablePrice) * 100;

    this.form.controls['percScontoNettoAzioni'].setValue(this.reduceDecimalFloat(percentageNetShareDiscount));

    this.percScontoNettoAzioniChange()
  }

  private updateProvvigioneTotale() {
    LoggerService.log('updateProvvigioneTotale');

    const financialServices = this.form.controls['serviziFinanziari'] as FormArray;
    const sellerCommissions = this.form.controls['provvigioneNettoAzioni'].value;
    let totalCommissionServices = 0;
    let totCommissionsAccessory = Registry.getInstance().get('totCommissionsAccessory') ?? 0;

    financialServices.value.forEach((service) => {
      totalCommissionServices = totalCommissionServices + service.anagServizioPratica.provvigioneAccessorio;
    })

    totCommissionsAccessory = totCommissionsAccessory + totalCommissionServices;

    if (sellerCommissions === 0) {
      LoggerService.error('Commissione venditore non impostata');

      return;
    }

    const totalCommission = sellerCommissions + totCommissionsAccessory;
    this.form.controls['provvigioneTotale'].setValue(this.reduceDecimalFloat(totalCommission));
  }

  private updateTotaleAzioni() {
    LoggerService.log('updateTotaleAzioni');

    const practice = <PracticeModel>this.form.getRawValue();

    let sumAction: number = practice.azioni.reduce((accumulator, object) => {
      return (object.anagAzionePratica && object.anagAzionePratica.conteggiaAzione) ? accumulator + object.importo : accumulator;
    }, 0);

    this.form.controls['totaleAzioni'].setValue(this.reduceDecimalFloat(sumAction));
  }

  private reduceDecimalFloat(float: number): number {
    return parseFloat(float.toFixed(2));
  }
}
