import {Component, OnInit} from '@angular/core';
import {AutoCompleteComponent} from "@components/dynamic-form/auto-complete/auto-complete.component";
import {map, startWith} from "rxjs/operators";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {Registry} from "@/registry.service";

@Component({
  selector: 'app-auto-complete-action-codes',
  templateUrl: 'auto-complete-action-codes.html',
  styleUrls: ['../../auto-complete.component.scss']
})
export class AutoCompleteActionCodesComponent extends AutoCompleteComponent implements OnInit {
  faIcon: any;
  showAlert: boolean = false;

  displayFn(selectedoption: any): string {
    return selectedoption && selectedoption.descrizione && selectedoption.codiceAzione ?
      `${selectedoption.codiceAzione} - ${selectedoption.descrizione}` :
      '';
  }

  getValueFormatted(action): string {
    return action && action.descrizione && action.codiceAzione ? `${action.codiceAzione} - ${action.descrizione}` : ''
  }


  ngOnInit() {
    const nameClass = `AutoCompleteActionCodesComponent-${this.keyformArrayName}-${this.indexRow}`

    Registry.getInstance().set(nameClass, this)

    this.faIcon = faTriangleExclamation
    this.setControl()

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => {
        const optionValue = typeof value === 'string' ? value : this.getValueFormatted(value)

        return !optionValue ? this.meta.options.slice() : this._filter(optionValue);
      }),
    )

    if (this.control.getRawValue() && this.control.getRawValue().codiceAzione) {
      const anagAction = this._filter(this.control.getRawValue().codiceAzione)[0] ?? []

      if (
        anagAction.length !== 0 &&
        anagAction.conteggiaAzione === false
      ) {
        this.showAlert = true
      } else {
        this.showAlert = false
      }
    }
  }

  protected _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.meta.options.filter(option => {
      return this.getValueFormatted(option).toLowerCase().includes(filterValue)
    });
  }
}
