import {Component, Input, OnInit} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {AbstractControl, FormGroup, Validators} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent extends InputFieldComponent implements OnInit {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup

  ngOnInit() {
    this.setControl()
  }

  isControlRequired(abstractControl: AbstractControl) {
    return abstractControl.hasValidator(Validators.required);
  }

  dispatchChangeEvent(element: HTMLInputElement) {
    element.dispatchEvent(new Event('change'))
  }
}
