import {ButtonDeleteBrandComponent} from "@components/button/tables/brands/button-delete-brand.component";
import {ButtonDetailBrandComponent} from "@components/button/tables/brands/button-modify-brand.component";
import {Subject} from "rxjs";

export class BrandSettings {

  public static gridSettings(refreshTableList: Subject<any>) {
    return {
      actions: false,
      noDataMessage: 'Nessun dato trovato',
      attr: {
        class: 'table table-bordered'
      },
      pager:
        {
          display: true,
          perPage: 25
        },
      columns: {
        detailButton: {
          type: 'custom',
          renderComponent: ButtonDetailBrandComponent.setRefreshTableList(refreshTableList),
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        deleteButton: {
          type: 'custom',
          renderComponent: ButtonDeleteBrandComponent.setRefreshTableList(refreshTableList),
          editable: false,
          addable: false,
          filter: false,
          width: '50px'
        },
        id: {
          title: 'Id',
          width: '100px'
        },
        tipoVeicolo: {
          title: 'Tipo veicolo',
          filter: {
            type: 'list',
            config: {
              list: [
                {value: 'NUOVO', title: 'NUOVO'},
                {value: 'USATO', title: 'USATO'},
              ],
            },
          },
        },
        codiceMarca: {
          title: 'Codice'
        },
        descrizioneMarca: {
          title: 'Descrizione'
        },
        categoriaVeicolo: {
          title: 'Categoria veicolo',
          filter: {
            type: 'list',
            config: {
              list: [
                {value: 'AUTOVETTURA', title: 'AUTOVETTURA'},
                {value: 'VEICOLO_COMMERCIALE', title: 'VEICOLO COMMERCIALE'},
              ],
            },
          },
        },
        position: {
          title: 'Posizione',
          sortDirection: 'asc'
        },
      },
      delete: null
    };
  }
}
