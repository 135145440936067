import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {BankAccountService} from "@services/data-source-tables/bank-account.service";
import {BankAccountModel} from "@/models/tables/bank-account.model";
import {BankAccountFormSetting} from "@/form-settings/bank-account-form-setting";

@Component({
  selector: 'app-bank-account-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class BankAccountFormComponent extends DynamicFormComponent implements OnInit {
  constructor(protected router: Router, protected userService: UserService, protected bankAccountService: BankAccountService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let bankAccountModel = <BankAccountModel>this.form.getRawValue()

    this.bankAccountService.saveBankAccount(bankAccountModel).subscribe({
      next: () => {
        this.toastr.success('Banca salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveBankAccount failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della Banca.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = BankAccountFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  bottomButtonAction() {
    if (this.data.id) {
      Registry.getInstance().get('DialogBankAccountComponentModify').close()
    } else {
      Registry.getInstance().get('DialogBankAccountComponentNew').close()
    }
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogBankAccountComponentModify').close()
    } else {
      Registry.getInstance().get('DialogBankAccountComponentNew').close()
    }

    Registry.getInstance().get('BankAccountComponent').loadBankAccount()
  }

}
