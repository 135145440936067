import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {
  DialogActionPracticeComponent
} from "@pages/tables/action-practices/dialog-action-practice/dialog-action-practice.component";
import {ActionPracticesModel} from "@/models/tables/action-practices.model";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {ActionPracticesService} from "@services/data-source-tables/action-practices.service";

@Component({
  selector: 'app-button-detail-action-practice',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailActionPracticeComponent extends ButtonComponent implements OnInit {
  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private actionPractice: ActionPracticesModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, protected actionPracticesService: ActionPracticesService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Azione pratica'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.actionPractice = <ActionPracticesModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogActionPracticeComponent, {
      data: this.actionPractice,
    });

    Registry.getInstance().set('DialogActionPracticeComponentModify', this.dialogOpened)
  }

}
