import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {BrandGoalsModel} from "@/models/brand-goals.model";
import {BrandGoalsService} from "@services/data-source-tables/brand-goals.service";
import {
  DialogBrandGoalsComponent
} from "@pages/practices-management/brand-goals/dialog-brand-goals/dialog-brand-goals.component";

@Component({
  selector: 'app-button-detail-brand-goals',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDetailBrandGoalsComponent extends ButtonComponent implements OnInit {
  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private brandGoalsModel: BrandGoalsModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, protected brandGoalsService: BrandGoalsService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Obiettivo marca'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.brandGoalsModel = <BrandGoalsModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogBrandGoalsComponent, {
      data: this.brandGoalsModel,
    });

    Registry.getInstance().set('DialogBrandGoalsComponentModify', this.dialogOpened)
  }

}
