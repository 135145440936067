import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {Component, OnInit} from "@angular/core";
import {ButtonComponent} from "@components/button/button.component";
import {ToastrService} from "ngx-toastr";
import {ServicePracticesModel} from "@/models/tables/service-practices.model";
import {ServicePracticesService} from "@services/data-source-tables/service-practices.service";

@Component({
  selector: 'app-button-service-practice-delete',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDeleteServicePracticeComponent extends ButtonComponent implements OnInit {
  faIcon = faTrashCan;
  private servicePracticesModel: ServicePracticesModel;
  private rowData: any;

  constructor(protected router: Router, public dialog: MatDialog, protected practicesService: ServicePracticesService, protected toastr: ToastrService) {
    super(router, toastr);

    this.color = 'danger';
    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
    this.smallBtn = true;
  }

  ngOnInit() {
    this.servicePracticesModel = <ServicePracticesModel>this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteSubAgent => {
      if (deleteSubAgent) {
        this.practicesService.deleteServicePractice(this.servicePracticesModel.id).subscribe({
          next: () => {
            this.toastr.success('Servizio pratica cancellato correttamente!');

            Registry.getInstance().get('ServicePracticesComponent').loadServicePractices()
          },
          error: (error) => {
            LoggerService.error('deleteSubAgent failed!')
            LoggerService.error(error);

            this.toastr.error(`Errore durante la cancellazione del Servizio pratica.`);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo Servizio pratica?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellata il Servizio pratica non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
