import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {DialogBrandComponent} from "@pages/tables/brand/dialog-brand/dialog-brand.component";
import {BrandModel} from "@/models/tables/brand.model";
import {Subject} from "rxjs";

@Component({
  selector: 'app-button-detail-brand',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailBrandComponent extends ButtonComponent implements OnInit {
  static refreshTableList: Subject<any>;
  faIcon = faPenToSquare;
  private closeDialog = new Subject<any>();
  private dialogBrand: MatDialogRef<any>
  private brand: BrandModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Brand'
    this.smallBtn = true;

    this.closeDialog.subscribe(() => {
      this.dialogBrand.close();
      ButtonDetailBrandComponent.refreshTableListAction();
    });
  }

  static refreshTableListAction() {
    this.refreshTableList.next();
  }

  static setRefreshTableList(refreshTableList: Subject<any>) {
    this.refreshTableList = refreshTableList;

    return this;
  }

  ngOnInit() {
    this.brand = <BrandModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogBrand = this.dialog.open(DialogBrandComponent, {
      data: {
        model: this.brand,
        closeDialog: this.closeDialog
      },
    });
  }
}
