import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {DeliveryCardboardService} from "@services/delivery-cardboard.service";
import {DeliveryCardboardDataSource} from "@components/new-table/data-sources/delivery-cardboard.dataSource";
import {DeliveryCardboardManagementSettings} from "@/table-settings/practices/delivery-cardboard-management-settings";
import {
  DeliveryCardBoardModel,
  DeliveryCardboardPractice,
  TableDeliveryCardBoardModel
} from "@/models/tables/delivery-cardboard.models";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {UserService} from "@services/admin/user.service";
import {DeliveryCardboardFormSetting} from "@/form-settings/delivery-cardboard-form-setting";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {startWith, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-delivery-cardboard-management',
  templateUrl: './delivery-cardboard-management.component.html',
  styleUrls: ['./delivery-cardboard-management.component.scss']
})
export class DeliveryCardboardManagementComponent implements OnInit, OnDestroy {

  displayedColumns = DeliveryCardboardManagementSettings.getColumnsSetting(this.deliveryCardboardService)
  dataSource = new DeliveryCardboardDataSource(this.deliveryCardboardService);
  reloadTrigger$ = new Subject<any>();
  triggerDate$ = new Subject<any>();
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  meta: Array<any> = [];
  praticheOptions: DeliveryCardboardPractice[] = [];
  formSetting = DeliveryCardboardFormSetting.getMeta()
    .reduce((acc, {key}) => {
      switch (key) {
        case 'id':
          break;
        case 'creator':
        case 'modifier':
          acc[key] = this.userService.getUserName();
          break;
        case 'created':
        case 'modified':
          acc[key] = new Date().toISOString();
          break;
        default:
          acc[key] = null;
          break;
      }
      return acc;
    }, {} as any)
  form: FormGroup;
  newItemForm: FormGroup;
  filteredPraticaOptions$ = new BehaviorSubject<DeliveryCardboardPractice[]>([]);
  codPraticaSubDestroy$ = new Subject<void>();
  datePickerData: object = {
    "classToUse": "",
    "controlType": "date",
    "dateType": "MM/YYYY",
    "placeholder": "MM/YYYY",
    "maxDate": true,
    "label": "Periodo mese consegna",
    "class": "md:w-6/12 lg:w-3/12 w-full",
  };
  objectDataSelected: object
  public yearFilter: number;
  public monthFilter: number;
  private readonly newItemFormExcludedControls = [
    'progressivoAnnoMeseMarca',
    'progressivoAnnoMarca'
  ]
  newItemInlineColumnsSetting = DeliveryCardboardManagementSettings.getColumnsSetting(this.deliveryCardboardService)
    .reduce((acc, curr) => {
      if (this.newItemFormExcludedControls.includes(curr.name)) {
        curr.type = 'hidden';
      }
      acc.push(curr);
      return acc;
    }, [])

  constructor(private deliveryCardboardService: DeliveryCardboardService, protected userService: UserService) {
    this.setupMeta();
    const today = new Date()
    this.yearFilter = today.getFullYear()
    this.monthFilter = today.getMonth() + 1
  }

  ngOnInit() {
    this.deliveryCardboardService.getPractices().subscribe(v => {
      this.praticheOptions = v;
      this.filteredPraticaOptions$.next(this.praticheOptions);
    })

    // this.form.controls['creator'].setValue(this.userService.getUserName());

    const codPraticaIdx = this.meta.findIndex(({key}) => key === 'codicePratica');
    // setting codice Pratica autocomplete options
    this.filteredPraticaOptions$.subscribe(v => {
      this.meta[codPraticaIdx].options = v.length ? v : this.praticheOptions;
    });

    this.newItemForm = this.createForm(this.formSetting);
    this.setFormBindings(this.newItemForm);

    this.triggerDate$.subscribe({
      next: (objectDate) => {
        this.yearFilter = objectDate.year;
        this.monthFilter = objectDate.month;

        this.objectDataSelected = objectDate

      }
    });
  }

  ngOnDestroy() {
    this.codPraticaSubDestroy$.next();
  }

  findControlByName(controlName: string) {
    return this.meta.find(({key}) => key === controlName);
  }

  btnUpdateClick() {
    this.reloadTrigger$.next();
    this.triggerDate$.next(this.objectDataSelected);
  }

  btnDeleteClick({id}: TableDeliveryCardBoardModel) {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deletePractice => {
      if (deletePractice) {
        this.deliveryCardboardService.deleteCardBoard(id).subscribe({
          next: () => {
            this.reloadTrigger$.next(1);
            this.toastr.success('Numeratore consegna cancellato correttamente!');
          },
          error: (error) => {
            this.toastr.error(`Errore durante la cancellazione del Numeratore consegna.`);

            LoggerService.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  btnDetailsClick(row: TableDeliveryCardBoardModel) {
    const editForm = this.createForm(row);
    this.setFormBindings(editForm);
    this.form = editForm;
  }

  storeCardboard(data?: DeliveryCardBoardModel) {
    let cardBoardModel = data ?? <DeliveryCardBoardModel>this.form.getRawValue();

    if (data?.codicePratica)
      data.codicePratica = typeof data.codicePratica === 'object' ? (data.codicePratica as DeliveryCardboardPractice).codicePratica : data.codicePratica;

    this.deliveryCardboardService.saveCardBoard(cardBoardModel).subscribe({
      next: () => {
        this.toastr.success('Numeratore consegna salvato correttamente!');

        this.reloadTrigger$.next();
        this.newItemForm.reset();
      },
      error: (error) => {
        LoggerService.error('save Numeratore consegna failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Numeratore consegna.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  changeTransmission(model?: DeliveryCardBoardModel) {
    this.deliveryCardboardService.updateDeliveryTransmitted(model.id, model.consegnaTrasmessa).subscribe({
      next: () => {
        this.toastr.success('Consegna Trasmessa aggiornata!');

        this.reloadTrigger$.next()
      },
      error: (error) => {
        LoggerService.error('updateDeliveryTransmitted failed!');
        LoggerService.error(error);

        this.toastr.error(`Modifica Consegna Trasmessa fallita!`);

        this.reloadTrigger$.next()

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = DeliveryCardboardFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
    const codPraticaIdx = this.meta.findIndex(({key}) => key === 'codicePratica');
    // setting codice Pratica autocomplete options
    this.meta[codPraticaIdx].options = this.praticheOptions;
  }

  protected createForm(row: DeliveryCardBoardModel, options?: { omitDisabled: boolean }) {
    const newForm = new FormGroup({});
    Object.keys(row).forEach(k => {
      const control = this.findControlByName(k);
      if (!control || control.controlType === 'label') return;

      let formControlOptions = [];
      let disabled = false;

      if (control.required) {
        formControlOptions.push(Validators.required)
      }

      if (control.disabled && !options?.omitDisabled) {
        disabled = true
      }

      if (control.additionalValidators)
        formControlOptions = formControlOptions.concat(control.additionalValidators);

      const newControl = new FormControl({
        value: row[k],
        disabled: disabled,
      }, formControlOptions)

      newForm.setControl(k, newControl);
    })

    return newForm;
  }

  private setFormBindings(form: FormGroup) {
    form.get('codicePratica').valueChanges.pipe(
      takeUntil(this.codPraticaSubDestroy$),
      startWith('')
    ).subscribe(value => {
      if (value && typeof value === 'object') {
        const {codiceMarca, targa, cliente, destinatarioFattura, descrizioneVenditore, codicePratica} = value;

        form.get('marca').setValue(codiceMarca);
        form.get('targa').setValue(targa);
        form.get('cliente').setValue(cliente);
        form.get('destinatarioFattura').setValue(destinatarioFattura);
        form.get('venditore').setValue(descrizioneVenditore);

        this.filteredPraticaOptions$.next(this._filterPratiche(codicePratica));
      } else {
        this.filteredPraticaOptions$.next(this._filterPratiche(value));
      }
    });
  }

  private _filterPratiche(filterValue: DeliveryCardboardPractice["codicePratica"]): DeliveryCardboardPractice[] {
    return this.praticheOptions.filter(option => option.codicePratica.includes(filterValue));
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo Numeratore consegna?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellato non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
