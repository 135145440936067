import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlBase} from "@components/dynamic-form/control-base";
import {FormGroup} from "@angular/forms";
import {InputFieldComponent} from "@components/dynamic-form/input-field.component";

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent extends InputFieldComponent implements OnChanges, OnInit {
  @Input() meta!: ControlBase
  @Input() form!: FormGroup
  @Input() indexRow: number = null
  @Input() keyformArrayName: any

  ngOnInit() {
    this.setControl()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setControl()

    if (this.meta.isObject && this.control.value) {
      const value = this.control.value
      const newValue = value[this.meta.objectKeys[0]] + ' - ' + value[this.meta.objectKeys[1]]

      this.control.setValue(newValue)
    }
  }
}
