import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {UploadCircularBigDiscountFormSetting} from "@/form-settings/upload-circular-big-discount-form-setting";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-form-upload-circular',
  templateUrl: '../../../components/dynamic-form/dynamic-form.component.html',
})
export class FormUploadCircularComponent extends DynamicFormComponent {

  encType: string = 'multipart/form-data'

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService, private http: HttpClient) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = false
    this.submitTopButton = false
    this.primaryButton = 'Carica'

    Registry.getInstance().set('FormUploadCircularComponent', this)
  }

  setupMeta(): void {
    this.meta = UploadCircularBigDiscountFormSetting
      .setClassToCall(FormUploadCircularComponent.name)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  onSubmit() {
    const formData = new FormData();
  }
}
