import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '@components/button/button.component';
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {User} from "@/models/user.model";
import {UserService} from "@services/admin/user.service";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-button-user-delete',
  templateUrl: '../button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class ButtonDeleteUserComponent extends ButtonComponent implements OnInit {
  faIcon = faTrashCan;
  private user: User;
  private rowData: any;

  constructor(protected router: Router, public dialog: MatDialog, private userService: UserService, protected toastr: ToastrService) {
    super(router, toastr);

    const registry = Registry.getInstance()
    this.userService = registry.get('userService')
    this.color = 'danger';
    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
  }

  ngOnInit() {
    this.user = this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteUser => {
      if (deleteUser) {
        this.userService.deleteUser(this.user.username).subscribe({
          next: () => {
            this.toastr.success('Utente cancellato correttamente!');

            Registry.getInstance().get('UsersComponent').refreshUsers()
          },
          error: (error) => {
            LoggerService.error('deleteUser failed!')
            LoggerService.error(error);

            this.toastr.error(`Errore durante la cancellazione dell'utente.`);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo utente?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellato l'utente non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
