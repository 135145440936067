import {ControlBase} from "@components/dynamic-form/control-base";
import {ControlType} from "@components/dynamic-form/control-type";
import {DropdownControl} from "@components/dynamic-form/dropdown/dropdown-control";

export class RentalPracticeFormMeta {
  protected static classToCall: string
  private static rows: ControlBase[] = [];

  public static getMeta(): Array<any> {
    if (this.rows.length <= 0) {
      this.addRowId();
      this.addRowPraticaId();
      this.addRowCanale();
      this.addRowCategoriaVeicolo();
      this.addRowMarca();
      this.addRowModello();
      this.addRowTarga();
      this.addRowCanoneFinanziario();
      this.addRowCanoneServizio();
      this.addRowCanoneTotale();
      this.addRowCodiceCentroCosto();
      this.addRowCostoEccedenzaKm();
      this.addRowDataMad();
      this.addRowDataScadenza();
      this.addRowDescCentroCosto();
      this.addRowDurataContratto();
      this.addRowFamiglia();
      this.addRowKmContratto();
      this.addRowPercEsuberoKm();
      this.addRowRimborsoKm();
      this.addRowTipoGomme();
      this.addRowStatoContratto();
    }

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowCanoneFinanziario() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'canoneFinanziario';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Canone Finanziario';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowCanoneServizio() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'canoneServizio';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Canone Servizio';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowCanoneTotale() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'canoneTotale';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Canone Totale';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowCategoriaVeicolo() {
    const inputComponent = {} as DropdownControl;
    inputComponent.key = 'categoriaVeicolo';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Categoria Veicolo';
    inputComponent.class = "w-3/12";
    inputComponent.disabled = true;
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowCodiceCentroCosto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'codiceCentroCosto';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Codice Centro Costo';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowCostoEccedenzaKm() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'costoEccedenzaKm';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Costo Eccedenza Km';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowDataMad() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'dataMad';
    inputComponent.controlType = ControlType.date;
    inputComponent.label = 'Data Mad';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowDataScadenza() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'dataScadenza';
    inputComponent.controlType = ControlType.date;
    inputComponent.label = 'Data Scadenza';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowDescCentroCosto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'descCentroCosto';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Desc Centro Costo';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowDurataContratto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'durataContratto';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Durata Contratto';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowFamiglia() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'famiglia';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Famiglia';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowId() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'id';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.disabled = true;
    inputComponent.label = 'ID';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowKmContratto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'kmContratto';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Km Contratto';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowMarca() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'marca';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Marca';
    inputComponent.class = "w-3/12";
    inputComponent.disabled = true;
    inputComponent.isObject = true;
    inputComponent.objectKeys = ['codiceMarca', 'descrizioneMarca'];
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowModello() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'modello';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Modello';
    inputComponent.class = "w-6/12";
    inputComponent.disabled = true;
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowPercEsuberoKm() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'percEsuberoKm';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Perc Esubero Km';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowPraticaId() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'praticaId';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.disabled = true;
    inputComponent.label = 'Pratica ID';
    inputComponent.class = "w-3/12";
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowCanale() {
    const inputComponent = {} as DropdownControl;
    inputComponent.key = 'canale';
    inputComponent.controlType = ControlType.dropdown;
    inputComponent.options = [
      {key: 'BACKOFFICE', value: 'BACKOFFICE'},
      {key: 'OFFICINA', value: 'OFFICINA'},
    ]
    inputComponent.label = 'Canale';
    inputComponent.class = "w-3/12";
    inputComponent.required = true;

    this.rows.push(inputComponent);
  }

  private static addRowRimborsoKm() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'rimborsoKm';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Rimborso Km';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowStatoContratto() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'statoContratto';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Stato Contratto';
    inputComponent.disabled = true;
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }

  private static addRowTarga() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'targa';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Targa';
    inputComponent.class = "w-3/12";
    inputComponent.disabled = true;
    this.rows.push(inputComponent);
  }

  private static addRowTipoGomme() {
    const inputComponent = {} as ControlBase;
    inputComponent.key = 'tipoGomme';
    inputComponent.controlType = ControlType.textInput;
    inputComponent.label = 'Tipo Gomme';
    inputComponent.class = "w-3/12";
    this.rows.push(inputComponent);
  }
}
