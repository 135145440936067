import {Component, ViewChild} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {UserService} from "@services/admin/user.service";

type BrandImgInfo = {
  brand: string;
  imgSrc: string;
  imgSrcSet: string;
  classDiv: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  username: Array<any>;
  public brandsImgs: BrandImgInfo[] = [
    {
      brand: 'skoda',
      imgSrc: 'assets/img/skoda.PNG',
      imgSrcSet: 'assets/img/skoda.PNG',
      classDiv: 'w-8/12 m-auto'
    },
    {
      brand: 'skoda-plus',
      imgSrc: 'assets/img/skoda-plus.png',
      imgSrcSet: 'assets/img/skoda-plus.png',
      classDiv: 'w-10/12 m-auto'
    },
    {
      brand: 'volkswagen',
      imgSrc: 'assets/img/volkswagen.svg',
      imgSrcSet: 'assets/img/volkswagen.svg',
      classDiv: 'w-8/12 m-auto'
    },
    {
      brand: 'volkswagen-lcv',
      imgSrc: 'assets/img/volkswagen-lcv-it.svg',
      imgSrcSet: 'assets/img/volkswagen-lcv-it.svg',
      classDiv: 'w-9/12 m-auto'
    },
    {
      brand: 'volkswagen-usato-certificato',
      imgSrc: 'assets/img/VW_UsatoCertificato.png',
      imgSrcSet: 'assets/img/VW_UsatoCertificato.png',
      classDiv: 'w-11/12 m-auto'
    },
  ]

  public constructor(private userService: UserService) {
    this.username = this.userService.getUserName()
  }

  openFormContact() {
    window.open('https://forms.gle/SQejD1mM7un2Cbyq7', '_blank');
  }
}
