import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {BrandModel} from "@/models/tables/brand.model";

@Component({
  selector: 'app-dialog-brand',
  templateUrl: './dialog-brand.component.html',
  styleUrls: ['./dialog-brand.component.scss']
})
export class DialogBrandComponent {
  refreshTableList: Subject<any>;
  model: BrandModel;
  closeDialog: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = data.model;
    this.refreshTableList = data.refreshTableList;
    this.closeDialog = data.closeDialog;
  }
}
