import {Component, Input} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {BrandModel} from "@/models/tables/brand.model";
import {DialogBrandComponent} from "@pages/tables/brand/dialog-brand/dialog-brand.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-button-new-brand',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonNewBrandComponent extends ButtonComponent {
  faIcon = faPenToSquare;
  @Input() refreshTableList = new Subject<any>();
  @Input() closeDialog = new Subject<any>();
  private dialogBrand: MatDialogRef<any>

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = false
    this.additionalClass = 'ml-2'
    this.content = 'Nuova marca'

    this.closeDialog.subscribe(() => {
      this.dialogBrand.close();
      this.refreshTableList.next();
    });
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogBrand = this.dialog.open(DialogBrandComponent, {
      data: {
        model: {} as BrandModel,
        closeDialog: this.closeDialog
      },
    });
  }

}
