import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {AccessoryPracticeFormSetting} from "@/form-settings/accessory-practice-form-setting";
import {PracticeAccessoryModel} from "@/models/practice-accessory-model";
import {VehicleAccessoryModel} from "@/models/tables/vehicle-accessory.model";

@Component({
  selector: 'app-accessory-practice-form',
  templateUrl: '../../../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class AccessoryPracticeFormComponent extends DynamicFormComponent implements OnInit {
  private accessories: VehicleAccessoryModel[] = [];
  private accessoryListString: string[] = [];

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    Registry.getInstance().set('AccessoryPracticeFormComponent', this)
  }

  ngOnInit(): void {
    if (!this.data.pratica) {
      this.data.pratica = Registry.getInstance().get('PracticeDetailsComponent').data
      this.form.controls['pratica'].setValue(this.data.pratica)
    }

    const modelId = this.data.pratica.siglaModello.id

    this.practiceService.getAccessoriesByModel(modelId).subscribe((accessories) => {
      this.accessories = accessories

      for (const accessory of accessories) {
        this.accessoryListString.push(accessory.descrizioneAccessorio);
      }

      this.setupMeta()
    })
  }

  updateAccessoryFields(accessorySelected, indexRow = null) {
    const accessory = this.accessories.filter(v => v.descrizioneAccessorio === accessorySelected)

    this.form.controls['prezzo'].setValue(accessory[0].prezzo)
    this.form.controls['provvigione'].setValue(accessory[0].provvigione)

    this.setupMeta()
  }

  onSubmit() {
    let accessory = <PracticeAccessoryModel>this.form.getRawValue()

    this.practiceService.saveAccessory(accessory).subscribe({
      next: () => {
        this.toastr.success('Accessorio salvato correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveAccessory failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio dell'Accessorio.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  setupMeta(): void {
    this.meta = AccessoryPracticeFormSetting
      .setClassToCall('AccessoryPracticeFormComponent')
      .set('accessories', this.accessoryListString)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogDetailAccessoryPracticeComponent').close()
    } else {
      Registry.getInstance().get('DialogNewAccessoryPracticeComponent').close()
    }

    Registry.getInstance().get('PracticeDetailsComponent').refreshAccessories()
  }

  bottomButtonAction() {
    this.closeModalAndRefresh()
  }
}
