import {FormSetting} from "@/form-settings/form-setting";
import {ControlType} from "@components/dynamic-form/control-type";
import {ControlBase} from "@components/dynamic-form/control-base";

export class RentalMovementFormSetting extends FormSetting {

  private static isNew: boolean;
  private static rows: ControlBase[] = [];

  public static setIsNew(isNew: boolean) {
    this.isNew = isNew;

    return this;
  }

  public static getMeta(): Array<any> {
    if (this.rows.length <= 0) {
      if (!this.isNew) {
        this.addRowId();
      }

      this.addRowEConsegna();
      this.addRowCarburanteRientro();
      this.addRowCategoriaNoleggio();
      this.addRowCitta();
      this.addRowCognome();
      this.addRowDataConsegna();
      this.addRowDataContratto();
      this.addRowDataRientro();
      this.addRowDataUltimaRilevazioneKm();
      this.addRowKmConsegna();
      this.addRowKmRientro();
      this.addRowKmRilevati();
      this.addRowLuogoContratto();
      this.addRowNome();
      this.addRowPatente();
      this.addRowPatenteRilasciataDa();
      this.addRowPrezzo();
      this.addRowVia();
    }

    return [
      {
        "rows": this.rows,
        "order": 1
      },
    ]
  }

  private static addRowId() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'id';
    inputsComponent.disabled = true;
    inputsComponent.hidden = !this.isNew;
    inputsComponent.controlType = ControlType.textInput
    inputsComponent.label = 'ID';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowEConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'eConsegna';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'E Consegna';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowCarburanteRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'carburanteRientro';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Carburante Rientro';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowCategoriaNoleggio() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'categoriaNoleggio';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Categoria Noleggio';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowCitta() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'citta';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Città';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowCognome() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'cognome';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Cognome';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataConsegna';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Consegna';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataContratto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataContratto';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Contratto';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataRientro';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Rientro';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowDataUltimaRilevazioneKm() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'dataUltimaRilevazioneKm';
    inputsComponent.controlType = ControlType.date;
    inputsComponent.label = 'Data Ultima Rilevazione Km';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowKmConsegna() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmConsegna';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Km Consegna';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }


  private static addRowKmRientro() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmRientro';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Km Rientro';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowKmRilevati() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'kmRilevati';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Km Rilevati';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowLuogoContratto() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'luogoContratto';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Luogo Contratto';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowNome() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'nome';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Nome';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowPatente() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'patente';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Patente';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowPatenteRilasciataDa() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'patenteRilasciataDa';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Patente Rilasciata Da';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowPrezzo() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'prezzo';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Prezzo';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }

  private static addRowVia() {
    const inputsComponent = {} as ControlBase;
    inputsComponent.classToUse = this.classToCall;
    inputsComponent.key = 'via';
    inputsComponent.controlType = ControlType.textInput;
    inputsComponent.label = 'Via';
    inputsComponent.class = "w-4/12";

    this.rows.push(inputsComponent);
  }
}
