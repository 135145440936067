<!-- Main content -->
<ng-template #carBrandSmallBox let-brand="brand" let-classDiv="classDiv" let-imgSrc="imgSrc" let-imgSrcSet="imgSrcSet">
  <div class="col-2 mb-3">
    <div class="small-box bg-logo h-full flex">
      <img [alt]="brand" [ngClass]="classDiv" [src]="imgSrc" [srcset]="imgSrcSet">
    </div>
  </div>
</ng-template>
<section class="content">
  <div class="flex justify-center gap-2">
    <!-- Small boxes (Stat box) -->
    <ng-container *ngFor="let brandImg of brandsImgs"
                  [ngTemplateOutletContext]="{brand: brandImg.brand, imgSrc: brandImg.imgSrc, imgSrcSet: brandImg.imgSrcSet, classDiv: brandImg.classDiv}"
                  [ngTemplateOutlet]="carBrandSmallBox"></ng-container>
  </div>
</section>
<section class="content">
  <div class="flex justify-between small-box bg-info p-7">
    <div class="space-y-4 sm:basis-3/4">
      <div class="flex gap-2">
        <label class="text-center text-xl leading-snug">Benvenuto <b>{{ username }}</b>! 👋</label>
      </div>
      <div>
        <p class="text-justify text-lg">
          L'applicativo gestisce alcune aree operative della Concessionaria. <br/>
          Il Browser preferito per l’utilizzo della piattaforma è Google Chrome, ma non ci sono vincoli per
          l’uso di altri Browser. <br/>
          Se ci fossero anomalie segnalatele tramite il vostro referente in concessionaria.
        </p>
      </div>
    </div>
    <div class="hidden self-center pr-6 self-end basis-1/6 max-w-[130px] min-w-[70px] sm:block md:-mb-7 md:self-end">
      <img alt="user" src="assets/img/user.png" srcset="assets/img/user.png">
    </div>
  </div>
  <div class="small-box bg-info p-7">
    <p class="text-lg">Hai riscontrato un errore o vuoi inviarci dei feedback? Clicca sul bottone sottostante e
      inviaci una richiesta.</p>
    <app-button
      (btnClicked)="openFormContact()"
      [color]="'warning'"
      [content]="'Contattaci'"
      [icon]="'question'"
      [showIcon]="true"></app-button>
  </div>
</section>
