<div class="table-container">
  <app-new-table
    (btnDetailsClickEvent)="btnDetailsClickFunction($event)"
    [additionalFilters]="{id: id}"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [hideFilterHeaders]="true"
    [initialSort]="{
              active: 'id',
              direction: 'asc'
            }"
    [reloadTrigger$]="reloadTrigger$"
    [showPaginate]="false"
  ></app-new-table>
</div>
