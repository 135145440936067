export class RentalInsuranceModel {
  public id: number;
  public rc: string;
  public ifk: string;
  public pai: string;
  public compagnia: string;
  public numeroPolizza: string;
  public dataInizio: Date;
  public dataFine: Date;
  public stato: string;
}
