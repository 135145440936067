import {Component} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {BrandSettings} from "@/table-settings/tables/brand-settings";
import {BrandsService} from "@services/data-source-tables/brands.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-stock',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent {

  source: LocalDataSource = new LocalDataSource([]);
  settings: object;
  refreshTableList = new Subject<any>();

  constructor(private brandsService: BrandsService) {
    this.settings = BrandSettings.gridSettings(this.refreshTableList);

    this.refreshTableList.subscribe(() => {
      this.loadBrands();
    });

    this.loadBrands();
  }

  loadBrands() {
    this.brandsService.getAll().subscribe((brands) => {
      this.source.load(brands);
    });
  }
}
