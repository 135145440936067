import {Component, Input, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {LoggerService} from "@services/logger.service";
import {BrandsService} from "@services/data-source-tables/brands.service";
import {BrandModel} from "@/models/tables/brand.model";
import {BrandFormSetting} from "@/form-settings/brand-form-setting";
import {Subject} from "rxjs";

@Component({
  selector: 'app-brand-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class BrandFormComponent extends DynamicFormComponent implements OnInit {
  @Input() closeDialog: Subject<any>;

  constructor(protected router: Router, protected userService: UserService, protected brandsService: BrandsService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'
  }

  ngOnInit(): void {
    this.form.controls['creator'].setValue(this.userService.getUserName())
  }

  onSubmit() {
    let brand = <BrandModel>this.form.getRawValue()

    this.brandsService.saveBrand(brand).subscribe({
      next: () => {
        this.toastr.success('Brand salvata correttamente!');

        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('save Brand failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio del Brand.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000);
      },
    })
  }

  setupMeta(): void {
    this.meta = BrandFormSetting
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  bottomButtonAction() {
    this.closeDialog.next()
  }

}
