import {Component, OnInit} from "@angular/core";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {ToastrService} from "ngx-toastr";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {DynamicFormSubmitInterface} from "@components/dynamic-form/dynamic-form-submit-interface";
import {
  DialogFormRentalInsuranceComponent
} from "@pages/rental-management/rent-practice-detail/rental-insurance-list/dialog-form-rental-insurance/dialog-form-rental-insurance.component";
import {RentalInsuranceModel} from "@/models/rentals/rental-insurance-model";
import {RentalInsuranceService} from "@services/data-source-tables/rentals/rental-insurance.service";
import {RentalInsuranceFormSetting} from "@/form-settings/rentals/rental-insurance-form-setting";

@Component({
  selector: 'app-rental-insurance-form',
  templateUrl: '../../../../../components/dynamic-form/dynamic-form.component.html',
  styleUrls: ['../../../../../components/dynamic-form/dynamic-form.component.scss']
})
export class RentalInsuranceFormComponent extends DynamicFormComponent implements DynamicFormPopUpInterface, DynamicFormSubmitInterface, OnInit {
  private isNew: boolean = true;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    protected rentalInsuranceService: RentalInsuranceService,
  ) {
    super(toastr, userService)
    this.data = new RentalInsuranceModel()
    this.primaryButton = `Salva assicurazione`
    this.setupMeta()

    Registry.getInstance().set('RentalInsuranceFormComponent', this)
  }

  ngOnInit() {
    if (this.data.length > 0) {
      this.isNew = false
    }
  }

  setupMeta() {
    this.meta = RentalInsuranceFormSetting
      .setIsNew(this.isNew)
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  onSubmit() {
    let rentalInsuranceModel = <RentalInsuranceModel>this.form.getRawValue()

    this.rentalInsuranceService.updateRentalInsurance(rentalInsuranceModel).subscribe({
      next: (response) => {
        this.toastr.success('Assicurazione noleggio salvata correttamente!');

        this.bottomButtonAction()
      },
      error: (error) => {
        LoggerService.error('updateRentalInsurance failed!');
        LoggerService.error(error);

        this.toastr.error('Errore nel salvataggio!');

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  bottomButtonAction(): void {
    Registry.getInstance().get('DialogFormRentalInsuranceComponent').close()
  }

}
