import {TableColumnsSetting} from "@/interfaces/table-setting";
import {UsedVehiclesTableModel} from "@/models/tables/used-vehicles-table.model";
import {of} from "rxjs";

export class RentalVehicleTableSettings {
  public static getColumnsSetting(): TableColumnsSetting<keyof UsedVehiclesTableModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "btnDelete",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID',
        filter: {
          type: "input"
        }
      },
      {
        name: "canale",
        text: 'Canale',
        filter: {
          type: "select",
          options: of([
            {
              BACKOFFICE: 'BACKOFFICE',
            },
            {
              OFFICINA: 'OFFICINA'
            }
          ])
        }
      },
      {
        name: "praticaId",
        text: 'Numero Pratica',
        filter: {
          type: "input"
        }
      },
      {
        name: "targa",
        text: 'Targa',
        filter: {
          type: "input"
        }
      },
      {
        name: "marca",
        text: 'Marca',
        type: "object",
        propertyToShow: "descrizioneMarca",
        filter: {
          type: "input"
        }
      },
      {
        name: "famiglia",
        text: 'Famiglia',
        filter: {
          type: "input"
        }
      },
      {
        name: "modello",
        text: 'Modello',
        filter: {
          type: "input"
        }
      },
      {
        name: "statoContratto",
        text: 'Stato Contratto',
        filter: {
          type: "input"
        }
      },
      {
        name: "durataContratto",
        text: 'Durata Contratto',
        filter: {
          type: "input"
        }
      },
      {
        name: "kmContratto",
        text: 'Km Contratto',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataMad",
        text: 'Data MAD',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataScadenza",
        text: 'Data Scadenza',
        filter: {
          type: "input"
        }
      },
      {
        name: "canoneFinanziario",
        text: 'Canone Finanziario',
        filter: {
          type: "input"
        }
      },
      {
        name: "canoneServizio",
        text: 'Canone Servizio',
        filter: {
          type: "input"
        }
      },
      {
        name: "canoneTotale",
        text: 'Canone Totale',
        filter: {
          type: "input"
        }
      },
      {
        name: "percEsuberoKm",
        text: '% Esubero Km',
        filter: {
          type: "input"
        }
      },
      {
        name: "codiceCentroCosto",
        text: 'Codice Centro Costo',
        filter: {
          type: "input"
        }
      },
      {
        name: "descCentroCosto",
        text: 'Desc Centro Costo',
        filter: {
          type: "input"
        }
      },
      {
        name: "tipoGomme",
        text: 'Tipo Gomme',
        filter: {
          type: "input"
        }
      },
      {
        name: "costoEccedenzaKm",
        text: 'Costo Eccedenza Km',
        filter: {
          type: "input"
        }
      },
      {
        name: "rimborsoKm",
        text: 'Rimborso Km',
        filter: {
          type: "input"
        }
      }
    ];
  }
}
