import {TableColumnsSetting} from "@/interfaces/table-setting";
import {RentalMovementModel} from "@/models/rentals/rental-movement-model";

export class RentalInsuranceTableSettings {

  public static getColumnsSetting(): TableColumnsSetting<keyof RentalMovementModel> {
    return [
      {
        name: "btnDetails",
        customComponent: true,
        unsortable: true,
      },
      {
        name: "id",
        text: 'ID',
        filter: {
          type: "input"
        }
      },
      {
        name: "compagnia",
        text: 'Compagnia',
        filter: {
          type: "input"
        }
      },
      {
        name: "dataInizio",
        text: 'Data Inizio',
        filter: {
          type: "date"
        }
      },
      {
        name: "dataFine",
        text: 'Data Fine',
        filter: {
          type: "date"
        }
      },
      {
        name: "ifk",
        text: 'IFK',
        filter: {
          type: "input"
        }
      },
      {
        name: "numeroPolizza",
        text: 'Numero Polizza',
        filter: {
          type: "input"
        }
      },
      {
        name: "pai",
        text: 'PAI',
        filter: {
          type: "input"
        }
      },
      {
        name: "rc",
        text: 'RC',
        filter: {
          type: "input"
        }
      },
      {
        name: "stato",
        text: 'Stato',
        filter: {
          type: "input"
        }
      }
    ];
  }
}
