import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {Component, OnInit} from "@angular/core";
import {ButtonComponent} from "@components/button/button.component";
import {ToastrService} from "ngx-toastr";
import {RentalPracticesModel} from "@/models/tables/rental-practices.model";
import {RentalPracticesService} from "@services/data-source-tables/rentals/rental-practices.service";

@Component({
  selector: 'app-button-rental-practice-delete',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDeleteRentalPracticeComponent extends ButtonComponent implements OnInit {

  faIcon = faTrashCan;
  private rentalPracticeModel: RentalPracticesModel;
  private rowData: any;

  constructor(protected router: Router, public dialog: MatDialog, protected rentalPracticesService: RentalPracticesService, protected toastr: ToastrService) {
    super(router, toastr);

    this.color = 'danger';
    this.additionalClass = 'py-0.5 px-2.5'
    this.showIcon = true;
    this.smallBtn = true;
  }

  ngOnInit() {
    this.rentalPracticeModel = <RentalPracticesModel>this.rowData;
  }

  /**
   * Delete user
   */
  public actionClick(): void {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deleteRentalPractice => {
      if (deleteRentalPractice) {
        this.rentalPracticesService.deleteRentalPractice(this.rentalPracticeModel.id).subscribe({
          next: () => {
            this.toastr.success('Pratica Noleggio cancellata correttamente!');

            Registry.getInstance().get('RentalPracticesComponent').loadRentalPractices()
          },
          error: (error) => {
            LoggerService.error('deleteRentalPractice failed!')
            LoggerService.error(error);

            this.toastr.error(`Errore durante la cancellazione della Pratica Noleggio.`);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questa Pratica Noleggio?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellata la Pratica Noleggio non sarà più possibile recuperarla."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
