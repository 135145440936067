import {Component, inject} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {DataSourceTable} from "@components/new-table/data-sources/data-source-table";
import {RentalPracticeService} from "@services/data-source-tables/rentals/rental-practice.service";
import {RentalVehicleTableSettings} from "@/table-settings/rentals/rental-vehicle-table-settings";
import {DialogPopUpComponent} from "@components/dialog-pop-up/dialog-pop-up.component";
import {LoggerService} from "@services/logger.service";
import {RentalPracticeModel} from "@/models/rental-practice.model";
import {TablePracticeModel} from "@/models/tables/table-practices.model";

@Component({
  selector: 'app-rent-practice-list',
  templateUrl: './rental-practice-list.component.html',
  styleUrls: ['./rental-practice-list.component.scss']
})
export class RentalPracticeListComponent {
  displayedColumns = RentalVehicleTableSettings.getColumnsSetting()
  dataSource = new DataSourceTable(this.rentalPracticeService);
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  reloadTrigger$ = new Subject<any>();

  constructor(private rentalPracticeService: RentalPracticeService, private router: Router) {
  }

  btnDetailsClickFunction({id}: TablePracticeModel) {
    this.router.navigate([`/rental-practices/detail-practice/${id}`])
  }

  btnDeleteFunction({id: id}: RentalPracticeModel) {
    let dialogRef = this.dialog.open(DialogPopUpComponent, {
      width: 'auto',
      panelClass: 'custom-modalbox',
    });

    this.setupPopup(dialogRef)

    dialogRef.afterClosed().subscribe(deletePractice => {
      if (deletePractice) {
        this.rentalPracticeService.deleteRentalVehicle(id).subscribe({
          next: () => {
            this.reloadTrigger$.next(1);
            this.toastr.success('Veicolo per il noleggio cancellato correttamente!');
          },
          error: (error) => {
            this.toastr.error(`Errore durante la cancellazione del veicolo per il noleggio.`);

            LoggerService.error('deleteRentalVehicle failed!')
            LoggerService.error(error);
          },
          complete: () => {
            LoggerService.log('Request done')
          },
        });
      }
    });
  }

  private setupPopup(dialogRef: MatDialogRef<DialogPopUpComponent>) {
    dialogRef.componentInstance.title = 'Confermi di voler cancellare questo veicolo per il Noleggio?'
    dialogRef.componentInstance.content = "Attenzione una volta cancellato il veicolo non sarà più possibile recuperarlo."
    dialogRef.componentInstance.firstCTA = "Si cancella"
    dialogRef.componentInstance.secondCTA = "Annulla operazione"
    dialogRef.componentInstance.closeResultFirst = true
    dialogRef.componentInstance.closeResultSecond = false
  }
}
