import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {SellerModel} from "@/models/tables/seller.model";
import {DialogSellerComponent} from "@pages/tables/seller/dialog-seller/dialog-seller.component";

@Component({
  selector: 'app-button-detail-seller',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailSellerComponent extends ButtonComponent implements OnInit {

  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private sellerModel: SellerModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Venditore'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.sellerModel = <SellerModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogSellerComponent, {
      data: this.sellerModel,
    });

    Registry.getInstance().set('DialogSellerComponentModify', this.dialogOpened)
  }

}
