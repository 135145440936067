import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Subject} from "rxjs";

@Component({
  selector: 'app-dialog-new-used-vehicle',
  templateUrl: './dialog-new-used-vehicle.component.html',
})
export class DialogNewUsedVehicleComponent {
  closeDialogEvent = new Subject<any>();
  refreshUsedVehicleEvent = new Subject<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.closeDialogEvent = data.closeDialogEvent;
    this.refreshUsedVehicleEvent = data.refreshUsedVehicleEvent;
  }

}
