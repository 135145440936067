<div class="p-4 flex flex-wrap justify-center items-center gap-4 sm:justify-between">
  <label class="text-lg font-bold m-0">{{ tableTitle }}</label>
  <div class="inline-flex gap-2 ml-auto">
    <button (click)="btnUpdateClick.emit($event)" class="btn btn-primary inline-grid items-center">
      <i class="fa fa-refresh"></i>
      Aggiorna
    </button>
    <button (click)="btnNewClick.emit($event)" *ngIf="showNewButton" class="btn btn-secondary inline-grid items-center">
      Nuovo
    </button>
    <ng-content select="[action-button]"></ng-content>
  </div>
</div>
