import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from "@components/button/button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Registry} from "@/registry.service";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {
  DialogInsuranceServiceTypeComponent
} from "@pages/tables/insurance-service-type/dialog-insurance-service-type/dialog-insurance-service-type.component";
import {InsuranceServicesTypeService} from "@services/data-source-tables/insurance-services-type.service";
import {InsuranceServiceTypeModel} from "@/models/tables/insurance-service-type.model";

@Component({
  selector: 'app-button-detail-insurance-service-type',
  templateUrl: '../../button.component.html',
  styleUrls: ['../../button.component.scss'],
})
export class ButtonDetailInsuranceServiceTypeComponent extends ButtonComponent implements OnInit {
  faIcon = faPenToSquare;
  private dialogOpened: MatDialogRef<any>
  private insuranceServiceTypeModel: InsuranceServiceTypeModel;
  private rowData: any;

  constructor(protected router: Router, protected toastr: ToastrService, protected insuranceServicesTypeService: InsuranceServicesTypeService, private dialog: MatDialog) {
    super(router, toastr);

    this.showIcon = true
    this.additionalClass = 'py-0.5 px-2.5'
    this.toolTip = 'Dettaglio Servizio assicurativo'
    this.smallBtn = true;
  }

  ngOnInit() {
    this.insuranceServiceTypeModel = <InsuranceServiceTypeModel>this.rowData;
  }

  /**
   * Open modal new detail page
   */
  public actionClick(): void {
    this.dialogOpened = this.dialog.open(DialogInsuranceServiceTypeComponent, {
      data: this.insuranceServiceTypeModel,
    });

    Registry.getInstance().set('DialogInsuranceServiceTypeComponentModify', this.dialogOpened)
  }

}
