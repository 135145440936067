<div>
  <div class="buttons-over-form mb-3">
    <app-back-button></app-back-button>
  </div>
  <div class="container-box">
    <mat-tab-group *ngIf="tabs.length !== 0" animationDuration="0ms">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template [ngIf]="faIcon" mat-tab-label>
          <span *ngIf="faIcon">
            <fa-icon [icon]="faIcon"></fa-icon>
          </span>
          {{ tab.title }}
        </ng-template>
        <div [ngSwitch]="tab.title" class="content">
          <app-used-vehicle-form *ngSwitchCase="'Dettaglio'" [data]="data"></app-used-vehicle-form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
