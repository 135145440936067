import {Component} from '@angular/core';
import {UsedVehicleService} from "@services/used-vehicle.service";
import {UsedVehiclesSettings} from "@/table-settings/used-vehicles-settings";
import {UsedVehicleDataSource} from "@components/new-table/data-sources/used-vehicle.dataSource";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {UsedVehicleModel} from "@/models/usedVehicleModel";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {saveAs} from 'file-saver';
import {PracticesService} from "@services/practices.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Registry} from "@/registry.service";
import {
  DialogNewUsedVehicleComponent
} from "@pages/used-vehicle-management/dialog-new-used-vehicle/dialog-new-used-vehicle.component";

@Component({
  selector: 'app-used-vehicle',
  templateUrl: './used-vehicle.component.html',
  styleUrls: ['./used-vehicle.component.scss']
})
export class UsedVehicleComponent {
  displayedColumns = UsedVehiclesSettings.getColumnsSetting(this.usedVehicleService);
  dataSource = new UsedVehicleDataSource(this.usedVehicleService);
  reloadTrigger$ = new Subject<any>();
  newButtonTrigger$ = new Subject<any>();
  closeDialogTrigger$ = new Subject<any>();
  dialogNewUsedVehicleComponent: MatDialogRef<DialogNewUsedVehicleComponent, any>;
  protected readonly faDownload = faDownload;

  constructor(
    private usedVehicleService: UsedVehicleService,
    private router: Router,
    protected practiceService: PracticesService,
    public dialog: MatDialog
  ) {
    this.newButtonTrigger$.subscribe(() => {
      this.dialogNewUsedVehicleComponent = this.dialog.open(DialogNewUsedVehicleComponent, {
        data: {
          closeDialogEvent: this.closeDialogTrigger$,
          refreshUsedVehicleEvent: this.reloadTrigger$
        },
        width: 'auto',
        panelClass: 'custom-modalbox',
      });
    });

    Registry.getInstance().set('UsedVehicleComponent', this)

    this.closeDialogTrigger$.subscribe(() => {
      this.dialogNewUsedVehicleComponent.close();
    });
  }

  btnDetailsClickFunction({id}: UsedVehicleModel) {
    this.router.navigate([`/used-vehicle-management/details/${id}`])
  }

  downloadModelWithdrawalSheetUsedVehicle($event: any) {
    this.practiceService.downloadPermutaPdf($event.id).subscribe((res) => {
      saveAs(res, 'PresaInCarico-FoglioDiRitiro-' + $event.id + '.pdf');
    });
  }
}
