import {OptionSelect} from "@/models/option-select.model";

export class PracticeForm {
  protected static classToCall: string
  private static sellers: OptionSelect[] = [];
  private static brands: OptionSelect[] = [];
  private static typePracticeSelected: string;
  private static typePayments: OptionSelect[] = [];
  private static clientType: OptionSelect[] = [];
  private static rentalCodes: OptionSelect[] = [];
  private static actionCodes: any[] = [];
  private static bigBuyers: OptionSelect[] = [];
  private static rentalAgency: OptionSelect[] = [];
  private static sellerSelected: boolean = false;
  private static signalman: boolean = false;
  private static loyaltyServices: any[] = [];
  private static financialServices: any[] = [];
  private static typePracticeDisabled: boolean = false;
  private static models: any[] = [];
  private static subAgentUsers: OptionSelect[] = [];
  private static practiceTypes: OptionSelect[] = [];
  private static contactSource: any[] = [];
  private static warranties: any[] = [];
  private static hideTypeBuyer: boolean;
  private static fieldArriveFromCross: boolean = false;
  private static showCommissionFields: boolean = false;
  private static typeCommercialVehicleActive: boolean;
  private static typesCommercialVehicle: OptionSelect[] = [];
  private static percentagesCommissionSeller: OptionSelect[] = [];

  public static setFieldArriveFromCross(fieldArriveFromCross) {
    this.fieldArriveFromCross = fieldArriveFromCross

    return this
  }

  public static setHideTypeBuyer(hideTypeBuyer) {
    this.hideTypeBuyer = hideTypeBuyer

    return this
  }

  public static setShowCommissionFields(showCommissionFields: boolean) {
    this.showCommissionFields = showCommissionFields

    return this
  }

  public static setTypePracticeDisabled(disabled) {
    this.typePracticeDisabled = disabled

    return this
  }

  public static setTypeCommercialVehicleActive(active: boolean = false) {
    this.typeCommercialVehicleActive = active

    return this
  }

  public static setTypeCommercialVehicleList(typesCommercialVehicle: OptionSelect[]) {
    this.typesCommercialVehicle = typesCommercialVehicle

    return this
  }

  public static setPracticeTypes(practiceTypes) {
    this.practiceTypes = practiceTypes

    return this
  }

  public static setClassToCall(classes) {
    this.classToCall = classes

    return this
  }

  public static getForm() {
    if (this.typePracticeSelected === 'USATO') {
      return this.getMetaUsed();
    } else {
      return this.getMetaNew();
    }
  }

  public static setRentalCodes(rentalCodes) {
    this.rentalCodes = rentalCodes;

    return this;
  }

  public static setSignalman(signalman: boolean = false) {
    this.signalman = signalman;

    return this;
  }

  public static setRentalAgency(rentalAgency) {
    this.rentalAgency = rentalAgency;

    return this;
  }

  public static setSellerSelected(sellerSelected: boolean = false) {
    this.sellerSelected = sellerSelected;

    return this;
  }

  public static setSellers(sellers) {
    this.sellers = sellers;

    return this;
  }

  public static setClientType(clientType) {
    this.clientType = clientType;

    return this;
  }

  public static setActionCodes(actionCodes) {
    this.actionCodes = actionCodes;

    return this;
  }

  public static setBrands(brands) {
    this.brands = brands;

    return this;
  }

  public static setModels(models) {
    this.models = models;

    return this;
  }

  public static setBigBuyers(bigBuyers) {
    this.bigBuyers = bigBuyers;

    return this;
  }

  public static setLoyaltyServices(bigBuyers) {
    this.loyaltyServices = bigBuyers;

    return this;
  }

  public static setSubAgentUsers(subAgentUsers) {
    this.subAgentUsers = subAgentUsers;

    return this;
  }

  public static setFinancialServices(bigBuyers) {
    this.financialServices = bigBuyers;

    return this;
  }

  public static setTypePractice(value) {
    this.typePracticeSelected = value;

    return this;
  }

  public static setTypePayments(value) {
    this.typePayments = value;

    return this;
  }

  public static setContactSource(contactSource) {
    this.contactSource = contactSource;

    return this;
  }

  public static setWarranties(warranties) {
    this.warranties = warranties;

    return this;
  }

  public static setPercentagesCommissionSeller(percentagesCommissionSeller: OptionSelect[]) {
    this.percentagesCommissionSeller = percentagesCommissionSeller;

    return this;
  }

  private static getMetaNew() {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoPratica",
            "controlType": "dropdown",
            "label": "Tipo pratica",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "required": true,
            "disabled": this.typePracticeDisabled,
            "customAction": "practiceTypeChanged",
            "options": this.practiceTypes
          },
          {
            "classToUse": this.classToCall,
            "key": "data",
            "controlType": "date",
            "maxDate": true,
            "label": "Data",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "statoPratica",
            "controlType": "textinput",
            "label": "Stato pratica",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "required": false,
            "disabled": true
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "dataConsegna",
            "controlType": "date",
            "label": "Data Consegna",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "dataConsegnaReale",
            "controlType": "date",
            "label": "Data consegna reale",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full"
          },
          {
            "classToUse": this.classToCall,
            "key": "statoOrdineVgi",
            "controlType": "textinput",
            "label": "Stato ordine VGI",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "settimanaPrevistaProduzione",
            "controlType": "textinput",
            "label": "Settimana prevista produzione",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "venditore",
            "controlType": "dropdown",
            "compareFn": (o1: any, o2: any) => o1?.id === o2?.id,
            "label": "Venditore",
            "customAction": "sellerChanges",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": true,
            "options": this.sellers
          },
          {
            "classToUse": this.classToCall,
            "key": "utenteSubagente",
            "controlType": "dropdown",
            "label": "Nominativo Venditore",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": false,
            "disabled": true,
            "options": this.subAgentUsers
          },
          {
            "classToUse": this.classToCall,
            "key": "noteSegnalatore",
            "controlType": "textinput",
            "label": "Segnalatore",
            "order": 3,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": false,
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "textinput",
            "label": "Creatore",
            "order": 4,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": false,
            "disabled": true
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codicePratica",
            "controlType": "textinput",
            "label": "Numero pratica",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": true,
            "disabled": this.fieldArriveFromCross
          },
          {
            "classToUse": this.classToCall,
            "key": "cliente",
            "controlType": "textinput",
            "label": "CL prop. d'acq.",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "required": true
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceClienteContabilita",
            "controlType": "textinput",
            "label": "Codice B. Partner",
            "order": 3,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": false
          },
          {
            "classToUse": this.classToCall,
            "key": "secondoIntestatario",
            "controlType": "textinput",
            "label": "Secondo Intestatario",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "required": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "clienteInConquista",
            "controlType": "slide-toggle",
            "label": "Cliente in conquista",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "marca",
            "controlType": "dropdown",
            "compareFn": (o1, o2) => o1?.id === o2?.id,
            "label": "Marca",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": true,
            "options": this.brands,
            "customAction": "brandsChanges",
            "disabled": this.fieldArriveFromCross
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoCliente",
            "controlType": "dropdown",
            "label": "Tipo cliente",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
            "required": true,
            "options": this.clientType,
            "customAction": "typeClientChanges",
          },
          {
            "classToUse": this.classToCall,
            "key": "grandiAcquirenti",
            "controlType": "dropdown",
            "compareFn": (o1: any, o2: any) => o1?.id === o2?.id,
            "label": "Codice grandi acquirenti",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
            "options": this.bigBuyers
          },
          {
            "classToUse": this.classToCall,
            "key": "dataScadenzaAutoDemo",
            "controlType": "date",
            "label": "Data Scadenza Auto Demo",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
            "required": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "assegnatarioAutoDemo",
            "controlType": "textinput",
            "label": "Destinatario Auto Demo",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
            "required": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "depositoCauzionale",
            "controlType": "slide-toggle",
            "label": "Deposito cauzionale",
            "customAction": "securityDepositChanges",
            "order": 2,
            "class": "md:w-4/12 lg:w-3/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "importoDepositoCauzionale",
            "controlType": "number",
            "label": "Importo deposito cauzionale",
            "order": 2,
            "default": 0,
            "class": "md:w-4/12 lg:w-3/12 w-full"
          },
          {
            "classToUse": this.classToCall,
            "key": "nostroStock",
            "controlType": "slide-toggle",
            "label": "Nostro Stock",
            "order": 2,
            "class": "md:w-4/12 lg:w-3/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "daOrdinare",
            "controlType": "slide-toggle",
            "label": "Da ordinare",
            "order": 2,
            "class": "md:w-4/12 lg:w-3/12 w-full",
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "telaio",
            "controlType": "textinput",
            "label": "Telaio",
            "order": 1,
            "class": "md:w-12/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "targa",
            "controlType": "textinput",
            "label": "Targa",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoVeicoloCommerciale",
            "controlType": "dropdown",
            "label": "Tipo Veicolo Commerciale",
            "order": 2,
            "compareFn": (o1: any, o2: any) => o1?.id === o2?.id,
            "disabled": !this.typeCommercialVehicleActive,
            "required": this.typeCommercialVehicleActive,
            "options": this.typesCommercialVehicle,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "dataArrivo",
            "controlType": "date",
            "label": "Data Arrivo Prevista",
            "order": 3,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "dataArrivoReale",
            "controlType": "date",
            "label": "Data Arrivo Reale",
            "disabled": true,
            "order": 3,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "dataRitiroVettura",
            "controlType": "date",
            "label": "Data Ritiro Vettura",
            "disabled": true,
            "order": 3,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceModello",
            "controlType": "textinput",
            "label": "Codice Modello",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "siglaModello",
            "controlType": "autocomplete-model",
            "label": "Modello",
            "order": 2,
            "required": true,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "options": this.models,
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneModello",
            "controlType": "textinput",
            "label": "Descrizione Modello",
            "order": 3,
            "class": "md:w-full lg:w-4/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceColore",
            "controlType": "textinput",
            "label": "Codice Colore",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneColore",
            "controlType": "textinput",
            "label": "Descrizione Colore",
            "order": 2,
            "class": "md:w-6/12 lg:w-8/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codiceColoreInterno",
            "controlType": "textinput",
            "label": "Codice Colore Interno",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneColoreInterno",
            "controlType": "textinput",
            "label": "Descrizione Colore Interno",
            "order": 2,
            "class": "md:w-6/12 lg:w-8/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "prezzoImponibile",
            "controlType": "number",
            "label": "Prezzo imponibile IVA Esclusa",
            "order": 1,
            "default": 0,
            "customAction": "prezzoImponibileChange",
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "scontoTotale",
            "controlType": "number",
            "default": 0,
            "label": "Sconto totale IVA Esclusa",
            "order": 2,
            "customAction": "scontoTotaleChange",
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoPagamento",
            "controlType": "autocomplete-payment-type",
            "label": "Tipo Pagamento",
            "order": 1,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "customAction": "typePaymentChanges",
            "options": this.typePayments
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceTabellaFin",
            "controlType": "textinput",
            "label": "Codice Tabella Fin",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceNoleggio",
            "controlType": "dropdown",
            "compareFn": (o1: any, o2: any) => o1?.id === o2?.id,
            "label": "Codice Tipo Cliente Noleggio",
            "order": 3,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true,
            "options": this.rentalCodes
          },
          {
            "classToUse": this.classToCall,
            "key": "numPraticaFinanziamento",
            "controlType": "textinput",
            "label": "Numero Pratica",
            "order": 4,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "disabled": true
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "agenziaNoleggio",
            "controlType": "dropdown",
            "compareFn": (o1: any, o2: any) => o1?.id === o2?.id,
            "label": "Agenzia noleggio",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "options": this.rentalAgency,
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "noteTabellaNoleggio",
            "controlType": "textinput",
            "label": "Tabella noleggio",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoVetturaAziendale",
            "controlType": "dropdown",
            "label": "Veicoli aziendali",
            "order": 3,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true,
            "options": [
              {"value": "VA", "key": "VA"},
              {"value": "VN", "key": "VN"}
            ],
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "numeroRateFinanziamento",
            "controlType": "number",
            "default": 0,
            "label": "Numero rate",
            "order": 1,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true
          },
          {
            "classToUse": this.classToCall,
            "key": "importoRateFinanaziamento",
            "controlType": "number",
            "default": 0,
            "label": "Importo rate",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "acconto",
            "controlType": "number",
            "default": 0,
            "label": "Acconto",
            "order": 1,
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "saldo",
            "controlType": "number",
            "label": "Saldo",
            "default": 0,
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "azioni",
            "title": "CODICI AZIONE",
            "controlType": "repeater",
            "label": "",
            "order": 2,
            "class": "w-full",
            "repeaterRows": [],
            "buttonRepeater": "Aggiungi codice azione",
            "deleteAction": "removeCodiceAzione",
            "metaArray": this.getMetaArrayAction(),
            "formClass": this.getFormClass(),
            "hideNewRowButton": false,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "serviziFinanziari",
            "title": "SERVIZI FINANZIARI",
            "controlType": "repeater",
            "label": "",
            "order": 2,
            "class": "w-full",
            "repeaterRows": [],
            "buttonRepeater": "Aggiungi servizio",
            "deleteAction": "removeFinancialService",
            "metaArray": this.getMetaArrayFinancialServices(),
            "formClass": this.getFormClass(),
            "hideNewRowButton": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "serviziLoyalty",
            "title": "SERVIZI LOYALTY",
            "controlType": "repeater",
            "label": "",
            "order": 2,
            "class": "w-full",
            "repeaterRows": [],
            "buttonRepeater": "Aggiungi servizio",
            "metaArray": this.getMetaArrayLoyaltyServices(),
            "formClass": this.getFormClass(),
            "hideNewRowButton": false,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "totaleAzioni",
            "controlType": "number",
            "label": "Totale azioni",
            "order": 2,
            "default": 0,
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "scontoNettoAzioni",
            "controlType": "number",
            "label": "Sconto al netto delle azioni",
            "order": 2,
            "default": 0,
            "customAction": "scontoNettoAzioniChange",
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "percScontoNettoAzioni",
            "controlType": "number",
            "label": "% Sconto applicato al netto delle azioni",
            "order": 2,
            "default": 0,
            "customAction": "percScontoNettoAzioniChange",
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "percMargineGA",
            "controlType": "number",
            "label": "% Sconto minimo Codice GA",
            "order": 2,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "customAction": "percMargineGAChange",
          },
          {
            "classToUse": this.classToCall,
            "key": "margineGA",
            "controlType": "number",
            "label": "Sconto minimo Codice GA",
            "order": 2,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "contributoDealerObbligatorio",
            "controlType": "number",
            "label": "Contributo dealer obbligatorio",
            "order": 3,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "customAction": "calculatePercentageContributoDealerChanges",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "percContributoDealerObbligatorio",
            "controlType": "number",
            "label": "% Contributo dealer obbligatorio",
            "order": 4,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "percProvvigioneConcordata",
            "controlType": "number",
            "label": "% Provvigione Concordata netto azioni",
            "order": 2,
            "default": 0,
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "hide": !this.signalman,
            "customAction": "percProvvigioneConcordataChange",

          },
          {
            "classToUse": this.classToCall,
            "key": "percProvvigione",
            "controlType": "dropdown",
            "label": "% Provvigione Rimanente",
            "order": 2,
            "default": 0,
            "disabled": this.percentagesCommissionSeller.length === 0,
            "options": this.percentagesCommissionSeller,
            "customAction": "percProvvigioneChange",
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "hide": !this.signalman,
          },
          {
            "classToUse": this.classToCall,
            "key": "provvigioneSegnalatore",
            "controlType": "number",
            "label": "Provvigione al lordo R.A. IVA Escl.",
            "order": 2,
            "default": 0,
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "hide": !this.signalman,
          },
        ],
        "order": 1
      },
      {
        "rows": this.getCommissionFields(),
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "omaggi",
            "controlType": "textarea",
            "label": "Omaggi",
            "order": 2,
            "class": "w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "note",
            "controlType": "textarea",
            "label": "Note",
            "order": 2,
            "class": "w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "segnalatore",
            "controlType": "hidden",
            "order": 2,
            "required": true,
          },
        ],
        "order": 1
      },
    ].filter(n => n);
  }

  private static getCommissionFields() {
    return this.showCommissionFields ? [
      {
        "classToUse": this.classToCall,
        "key": "percProvvigione",
        "controlType": "dropdown",
        "label": "% Provvigione",
        "order": 2,
        "disabled": true,
        "default": 0,
        "options": this.percentagesCommissionSeller,
        "class": "md:w-6/12 lg:w-3/12 w-full",
        "hide": this.signalman,
        "customAction": "percProvvigioneChange",
      },
      {
        "classToUse": this.classToCall,
        "key": "provvigioneNettoAzioni",
        "controlType": "number",
        "label": "Provvigione venditore",
        "order": 2,
        "default": 0,
        "class": "md:w-6/12 lg:w-3/12 w-full",
        "hide": this.signalman,
        "disabled": true,
      },
      {
        "classToUse": this.classToCall,
        "key": "provvigioneAccessori",
        "controlType": "number",
        "default": 0,
        "label": "Provvigione accessori",
        "order": 2,
        "class": "md:w-6/12 lg:w-3/12 w-full",
        "disabled": true,
        "customAction": "provvigioneAccessoriChange",
        "hide": this.signalman
      },
      {
        "classToUse": this.classToCall,
        "key": "provvigioneTotale",
        "controlType": "number",
        "label": "Totale provvigione",
        "order": 2,
        "class": "md:w-6/12 lg:w-3/12 w-full",
        "default": 0,
        "disabled": true,
        "hide": this.signalman
      },
    ] : [];
  }

  private static getCommissionFieldsUsedPractice() {
    return [
      {
        "classToUse": this.classToCall,
        "key": "percProvvigione",
        "controlType": "number",
        "label": "% Provvigione",
        "order": 2,
        "default": 0,
        "class": "md:w-6/12 lg:w-4/12 w-full",
        "hide": this.signalman,
        "customAction": "percProvvigioneChangeUsed",
      },
      {
        "classToUse": this.classToCall,
        "key": "provvigioneNettoAzioni",
        "controlType": "number",
        "label": "Provvigione venditore",
        "order": 2,
        "default": 0,
        "class": "md:w-6/12 lg:w-4/12 w-full",
        "hide": this.signalman,
        "disabled": true,
      },
      {
        "classToUse": this.classToCall,
        "key": "provvigioneTotale",
        "controlType": "number",
        "label": "Totale provvigione",
        "order": 2,
        "class": "md:w-6/12 lg:w-4/12 w-full",
        "default": 0,
        "disabled": true,
      },
    ];
  }

  private static getMetaUsed() {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoPratica",
            "controlType": "dropdown",
            "label": "Tipo pratica",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "required": true,
            "disabled": this.typePracticeDisabled,
            "customAction": "practiceTypeChanged",
            "options": this.practiceTypes
          },
          {
            "classToUse": this.classToCall,
            "key": "data",
            "controlType": "date",
            "maxDate": true,
            "label": "Data",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoAcquirente",
            "controlType": this.hideTypeBuyer ? "hidden" : 'dropdown',
            "label": "Tipo Acquirente",
            "disabled": this.hideTypeBuyer,
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "options": [
              {"value": "Privato", "key": "PRIVATO"},
              {"value": "Commerciante", "key": "COMMERCIANTE"}
            ],
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "venditore",
            "controlType": "dropdown",
            "compareFn": (o1: any, o2: any) => o1?.id === o2?.id,
            "label": "Venditore",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "required": true,
            "options": this.sellers
          },
          {
            "classToUse": this.classToCall,
            "key": "creator",
            "controlType": "textinput",
            "label": "Creatore",
            "order": 2,
            "class": "md:w-6/12 lg:w-8/12 w-full",
            "required": true,
            "disabled": true
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "codicePratica",
            "controlType": "textinput",
            "label": "Numero pratica",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": true,
            "disabled": this.fieldArriveFromCross
          },
          {
            "classToUse": this.classToCall,
            "key": "cliente",
            "controlType": "textinput",
            "label": "Cliente",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceClienteContabilita",
            "controlType": "textinput",
            "label": "Codice B. Partner",
            "order": 3,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": false
          },
          {
            "classToUse": this.classToCall,
            "key": "secondoIntestatario",
            "controlType": "textinput",
            "label": "Secondo Intestatario",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "required": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "marca",
            "controlType": "dropdown",
            compareFn: (o1, o2) => o1?.id === o2?.id,
            "label": "Marca",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "required": true,
            "options": this.brands,
            "customAction": "brandsChanges",
            "disabled": this.fieldArriveFromCross
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoCliente",
            "controlType": "dropdown",
            "label": "Tipo cliente",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true,
            "options": this.clientType,
            "customAction": "typeClientChanges",
          },
          {
            "classToUse": this.classToCall,
            "key": "origineContatto",
            "controlType": "autocomplete-contact-source",
            "label": "Origine contatto",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "options": this.contactSource
          },
          {
            "classToUse": this.classToCall,
            "key": "depositoCauzionale",
            "controlType": "slide-toggle",
            "label": "Deposito cauzionale",
            "customAction": "securityDepositChanges",
            "order": 2,
            "class": "md:w-4/12 lg:w-4/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "prezzoImponibile",
            "controlType": "number",
            "label": "Prezzo imponibile IVA Esclusa",
            "order": 1,
            "default": 0,
            "customAction": "prezzoImponibileChangeUsed",
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "importoDepositoCauzionale",
            "controlType": "number",
            "label": "Importo deposito cauzionale",
            "order": 1,
            "default": 0,
            "class": "md:w-4/12 lg:w-6/12 w-full",
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "descrizioneModello",
            "controlType": "textinput",
            "label": "Descrizione Modello",
            "order": 1,
            "class": "w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "descrizioneColore",
            "controlType": "textinput",
            "label": "Descrizione Colore",
            "order": 1,
            "class": "w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "descrizioneColoreInterno",
            "controlType": "textinput",
            "label": "Descrizione Colore Interno",
            "order": 2,
            "class": "w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "tipoPagamento",
            "controlType": "autocomplete-payment-type",
            "label": "Tipo Pagamento",
            "order": 1,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "customAction": "typePaymentChanges",
            "options": this.typePayments
          },
          {
            "classToUse": this.classToCall,
            "key": "codiceTabellaFin",
            "controlType": "textinput",
            "label": "Codice Tabella Fin",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "numPraticaFinanziamento",
            "controlType": "textinput",
            "label": "Numero Pratica",
            "order": 2,
            "class": "md:w-6/12 lg:w-4/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "numeroRateFinanziamento",
            "controlType": "number",
            "label": "Numero rate",
            "default": 0,
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "importoRateFinanaziamento",
            "controlType": "number",
            "default": 0,
            "label": "Importo rate",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true,
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "acconto",
            "controlType": "number",
            "label": "Acconto",
            "order": 2,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "saldo",
            "controlType": "number",
            "label": "Saldo",
            "order": 2,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "garanzia",
            "controlType": "autocomplete-warranty",
            "label": "Garanzia",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "options": this.warranties
          },
          {
            "classToUse": this.classToCall,
            "key": "totaleVenditaCompresoTrapasso",
            "controlType": "number",
            "label": "Totale vendita compreso trapasso",
            "order": 2,
            "default": 0,
            "class": "md:w-6/12 lg:w-6/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoFattura",
            "controlType": "dropdown",
            "label": "Tipo Fattura",
            "order": 2,
            "class": "md:w-6/12 lg:w-3/12 w-full",
            "options": [
              {"value": "Fattura esente RM", "key": "FATTURA_ESENTE_RM"},
              {"value": "Fattura con IVA", "key": "FATTURA_CON_IVA"}
            ],
          }
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "serviziFinanziari",
            "title": "SERVIZI & ASSICURAZIONI",
            "controlType": "repeater",
            "label": "",
            "order": 2,
            "class": "w-full",
            "repeaterRows": [],
            "buttonRepeater": "Aggiungi servizio",
            "metaArray": this.getMetaArrayFinancialServices(),
            "formClass": this.getFormClass(),
            "hideNewRowButton": false,
          },
        ],
        "order": 1
      },
      {
        "rows": this.getCommissionFieldsUsedPractice(),
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "omaggi",
            "controlType": "textarea",
            "label": "Omaggi",
            "order": 2,
            "class": "w-full",
          },
        ],
        "order": 1
      },
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "note",
            "controlType": "textarea",
            "label": "Note",
            "order": 2,
            "class": "w-full",
          },
        ],
        "order": 1
      },
    ];
  }

  private static getMetaArrayAction() {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "anagAzionePratica",
            "controlType": "autocomplete-action-codes",
            "label": "Cod. Azione",
            "order": 2,
            "class": "md:w-4/12 lg:w-4/12 w-full",
            "customAction": "codeActionChanges",
            "required": true,
            "options": this.actionCodes,
            "formClass": this.getFormClass()
          },
          {
            "classToUse": this.classToCall,
            "key": "valore",
            "controlType": "number",
            "label": "Valore",
            "order": 2,
            "default": 0,
            "customAction": "amountActionChanges",
            "required": true,
            "class": "md:w-4/12 lg:w-2/12 w-full",
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoValore",
            "controlType": "dropdown",
            "label": "Tipo Valore",
            "order": 2,
            "customAction": "amountActionChanges",
            "required": true,
            "class": "md:w-4/12 lg:w-2/12 w-full",
            "options": [
              {"value": "Con IVA", "key": "CON_IVA"},
              {"value": "Senza IVA", "key": "SENZA_IVA"},
              {"value": "Percentuale", "key": "PERCENTUALE"},
            ],
          },
          {
            "classToUse": this.classToCall,
            "key": "importo",
            "controlType": "number",
            "label": "Importo IVA esclusa",
            "order": 2,
            "default": 0,
            "class": "md:w-4/12 lg:w-2/12 w-full",
            "required": true,
            "disabled": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "dataUltimaConsegnaAzione",
            "controlType": "date",
            "label": "Data u.c. azione",
            "order": 2,
            "class": "md:w-4/12 lg:w-2/12 w-full",
            "required": true,
          },
        ],
        "order": 1
      }
    ];
  }

  private static getMetaArrayFinancialServices() {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "anagServizioPratica",
            "controlType": "autocomplete-financial-services",
            "label": "Servizio",
            "order": 2,
            "class": "md:w-3/12 lg:w-3/12 w-full",
            "customAction": "financialServiceChanges",
            "options": this.financialServices,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "punteggio",
            "controlType": "number",
            "label": "Punteggio",
            "order": 2,
            "default": 0,
            "class": "md:w-3/12 lg:w-3/12 w-full",
            "disabled": true,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "note",
            "controlType": "textinput",
            "label": "Note",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true,
            "required": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoServizio",
            "controlType": "hidden",
            "label": "",
            "order": 2,
            "class": "",
            "required": true,
          },
        ],
        "order": 1
      }
    ];
  }

  private static getMetaArrayLoyaltyServices() {
    return [
      {
        "rows": [
          {
            "classToUse": this.classToCall,
            "key": "anagServizioPratica",
            "controlType": "autocomplete-loyalty-services",
            "label": "Servizio",
            "order": 2,
            "customAction": "loyaltyServiceChanges",
            "class": "md:w-3/12 lg:w-3/12 w-full",
            "options": this.loyaltyServices,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "punteggio",
            "controlType": "number",
            "label": "Punteggio",
            "order": 2,
            "default": 0,
            "class": "md:w-3/12 lg:w-3/12 w-full",
            "disabled": true,
            "required": true,
          },
          {
            "classToUse": this.classToCall,
            "key": "note",
            "controlType": "textinput",
            "label": "Note",
            "order": 2,
            "class": "md:w-6/12 lg:w-6/12 w-full",
            "disabled": true,
            "required": false,
          },
          {
            "classToUse": this.classToCall,
            "key": "tipoServizio",
            "controlType": "hidden",
            "label": "",
            "order": 2,
            "class": "",
            "required": true,
          },
        ],
        "order": 1
      }
    ];
  }

  private static getFormClass(): string {
    return 'NewPracticeFormComponent'
  }
}
