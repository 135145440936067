import {Component, OnInit} from '@angular/core';
import {DynamicFormComponent} from "@components/dynamic-form/dynamic-form.component";
import {Router} from "@angular/router";
import {UserService} from "@services/admin/user.service";
import {PracticesService} from "@services/practices.service";
import {LoggerService} from "@services/logger.service";
import {Registry} from "@/registry.service";
import {ToastrService} from "ngx-toastr";
import {PracticeCommunicationModel} from "@/models/practice-communication-model";
import {CommunicationPracticeFormSetting} from "@/form-settings/communication-practice-form-setting";
import {DynamicFormPopUpInterface} from "@components/dynamic-form/dynamic-form-pop-up-interface";

@Component({
  selector: 'app-communication-practice-form',
  templateUrl: '../../../../../../../components/dynamic-form/dynamic-form.component.html',
})
export class ComunicationPracticeFormComponent extends DynamicFormComponent implements OnInit, DynamicFormPopUpInterface {

  constructor(protected router: Router, protected userService: UserService, protected practiceService: PracticesService, protected toastr: ToastrService) {
    super(toastr, userService)

    this.setupMeta()
    this.submitButtonVisible = true
    this.primaryButton = 'Salva'
    this.secondaryBottomButton = 'Chiudi'

    Registry.getInstance().set('ComunicationPracticeFormComponent', this)
  }

  ngOnInit(): void {
    if (!this.data.pratica) {
      this.data.pratica = Registry.getInstance().get('PracticeDetailsComponent').data
      this.form.controls['pratica'].setValue(this.data.pratica)
    }
  }

  public onSubmit() {
    let communication = <PracticeCommunicationModel>this.form.getRawValue()

    this.practiceService.saveCommunication(communication).subscribe({
      next: () => {
        this.toastr.success('Comunicazione salvata correttamente!');

        this.closeModalAndRefresh()
      },
      error: (error) => {
        LoggerService.error('saveCommunication failed!');
        LoggerService.error(error);

        this.toastr.error(`Errore durante il salvataggio della comunicazione.`);

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
      complete: () => {
        LoggerService.log('Request done')

        setTimeout(() => {
          Registry.getInstance().set('saveInProgress', false)
        }, 1000)
      },
    })
  }

  bottomButtonAction() {
    this.closeModalAndRefresh()
  }

  setupMeta(): void {
    this.meta = CommunicationPracticeFormSetting
      .setClassToCall('ComunicationPracticeFormComponent')
      .getMeta()
      .sort((a: any, b: any) => a.order - b.order)
  }

  protected closeModalAndRefresh() {
    if (this.data.id) {
      Registry.getInstance().get('DialogDetailCommunicationPracticeComponent').close()
    } else {
      Registry.getInstance().get('DialogNewCommunicationPracticeComponent').close()
    }

    Registry.getInstance().get('PracticeDetailsComponent').refreshCommunications()
  }
}
